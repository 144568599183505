import LogisticCoordinator from '../../assets/img/logistics-coordinator.jpg';
import PODVerifier from '../../assets/img/pod-verifier.jpg';
import JuniorAccountant from '../../assets/img/junior-accountant.jpg';
import ItDataAnalyst from '../../assets/img/it-data-analyst.jpg';
import LogisticsManager from '../../assets/img/logistics-manager.jpg';
import BusinessDevelopmentManager from '../../assets/img/business-development-manager.jpg';

const CareerList = [
    {
        title: 'Logistics Coordinator',
        image: LogisticCoordinator,
        description: `Logistics Coordinator responsibilities include monitoring the shipping of products from origin to the destination, arranging the transportation, orchestrating the deliveries to meet customer demand with little to no delay.`,
        date: '01/31/2022',
        specification: [
            'Candidate must possess at Bachelor’s/College Degree in Logistic/Transportation or equivalent.',
            'Required Skill(s): logistics, supply chain.',
            'Preferably 1-4 Yrs Experienced Employee specialized in Logistics/Supply Chain or equivalent.',
            'Candidate must be willing to work in Makati City.',
            'Candidate must be willing to work from Monday to Saturday.',
            'Fresh graduates are open to apply.'
        ],
        responsibility: [
            'Responsible in monitoring the shipping of products from origin to the destination.',
            'Responsible in arranging the transportation, orchestrating the deliveries to meet customer demand with little to no delay with closed coordination.',
            'Responsible in preparing the loads for shipment',
            'Responsible in quality assurance to avoid quality control issues',
            'Responsible in managing the activities throughout the order fulfillment and transportation cycle to make sure established deadlines are met.',
            'Responsible in constant reviews of freight costs, transportation rates, and/or the prices of raw materials to keep costs down where possible.'
        ]
    },
    {
        title: 'POD Verifier',
        image: PODVerifier,
        description: `Ensure Proof of Delivery and billing policies and procedures are being followed. Ensure the accuracy of documents submitted by vendors or POD team members, which may largely on manual work. Coordinate with the internal Invoicing team for the POD documents handovers and transfers.`,
        date: '01/25/2022',
        specification: [
            'With degree in Finance, Economics, Business or similar.',
            'With experience working in Logistics Industry.',
            'With 1-3 years of experience handling and verifying Proof of Delivery (POD).',
            'With hands-on experience in large-volume billing and experiences working in a big team.',
            'With experience using Excel.',
            'Has experience in encoding and cross-checking the details of POD.'
        ],
        responsibility: [
            'Ensure Proof of Delivery and billing policies and procedures are being followed.',
            'Ensure the accuracy of documents submitted by vendors or POD team members, which is largely on manual work.',
            'Coordinate with the internal Invoicing team for the POD documents handovers and transfers.',
            'Strong statistics management skills to support review, monitoring, and reporting of this function.',
            'Ability to perform all other duties as assigned or requested.',
        ]
    },
    {
        title: 'Junior Accountant',
        image: JuniorAccountant,
        description: `As a Junior Accountant, you will play a vital role in the day-to-day operations of the Company. You will work closely with various stakeholders in ensuring the completion and accuracy of all financial reporting. Your deliverables to the finance department and others will contribute to the smooth and efficient running of the business.`,
        date: '01/25/2022',
        specification: [
            'Degree in Finance, Accounting, Business, Economics or similar.',
            'CPA or CMA (Certified Management Accountant) holder preferred.',
            'Minimum 2 – 4 years hands-on accounting, pricing or financial auditing work experience post-graduate.',
            'Proficiency in MS Excel and MS Word is highly desirable.',
            'Experiences with QuickBooks, XERO or equivalent preferred.',
            'Detail oriented and proactive in communications as a team player.'
        ],
        responsibility: [
            'As a Junior Accountant, you will play a vital role in the day-to-day operations of the Company. You will work closely with various stakeholders in ensuring the completion and accuracy of all financial reporting. Your deliverables to the finance department and others will contribute to the smooth and efficient running of the business.',
            'Support day to day accounting and bookkeeping tasks as required. Work with Accounting Manager on the preparation of monthly and annual ledger closings activities and reconciliations.',
            'Deal with contracts and rates. Assist in the day-to-day quote and pricing, documents preparation and filing, ensuring accuracy and liaison between finance and other internal teams.',
            'Assist in the financial planning and budgeting.',
            'Assist in regional consolidation and accounting in new countries.',
            'Perform other related ad-hoc duties as assigned.'
        ]
    },
    {
        title: 'IT Data Analyst',
        image: ItDataAnalyst,
        description: `IT Data Analyst Responsibilities include data interpretation, analyzing results using statistical techniques, cross checking the data and draw conclusion and recommendation from there,  developing and implementing data analyses.`,
        date: '12/29/2021',
        specification: [
            'Proven working experience as a data analyst or business data analyst.',
            'Technical expertise regarding data models, database design development, data mining and segmentation techniques.',
            'Strong knowledge of and experience with reporting packages (Business Objects etc), databases (SQL etc), programming (XML, Javascript, or ETL frameworks.',
            'Knowledge of statistics and experience using statistical packages for analyzing datasets (Excel, SPSS, SAS etc).',
        ],
        responsibility: [
            'Responsible in data interpretation, analyzing results using statistical techniques.',
            'Responsible in cross checking the data and draw conclusion and recommendation from there.',
            'Responsible in developing and implementing data analyses, data collection systems and other strategies that optimize statistical efficiency and quality.',
            'Responsible in acquiring data from primary or secondary data sources and maintaining databases.',
            'Responsible in Identifying, analyzing, and interpreting the trends or patterns in complex data sets.'
        ]
    },
    {
        title: 'Logistics Manager',
        image: LogisticsManager,
        description: `Logistics Manager responsibilities include selecting carriers and negotiate contracts and rates, planning and monitoring inbound and outgoing deliveries, supervising logistics, warehouse, transportation, and customer services.`,
        date: '12/29/2021',
        specification: [
            'Candidate must possess at least Bachelor’s/College Degree in Logistic/Transportation or equivalent.',
            'At least 4 Year(s) of working experience in the related field is required for this position.',
            'Required Skill(s): logistics, supply chain, lead or supervise a team.',
            'Preferably Supervisor/5 Yrs & Up Experienced Employee specialized in Logistics/Supply Chain or equivalent.',
            'Fresh graduates are open to apply.'
        ],
        responsibility: [
            'Responsible in selecting carriers and negotiate contracts and rates.',
            'Responsible in planning and monitoring inbound and outgoing deliveries.',
            'Responsible in supervising logistics, warehouse, transportation, and customer services.',
            'Responsible in responding to any issues or complaints.',
            'Responsible in developing quotes and proposals for clients.',
            'Responsible in researching the ideal shipping techniques, routing, and carriers.',
            'Responsible in working with other departments to incorporate logistics with company procedures and operations.'
        ]
    },
    {
        title: 'Business Development Manager',
        image: BusinessDevelopmentManager,
        description: `Business Development Manager responsibilities include contacting potential clients and arrange meetings, planning and overseeing new marketing initiatives, increasing the value of current customers while attracting new ones.`,
        date: '12/31/2021',
        specification: [
            'Candidate must possess at least Bachelor’s/College Degree in Marketing or equivalent.',
            'At least 3 Year(s) of working experience in the related field is required for this position.',
            'Required Skill(s): account management, client prospecting, and sales',
            'Preferably Assistant Manager/Manager specialized in Marketing/Business Development or equivalent.'
        ],
        responsibility: [
            'Responsible in contacting potential clients and arrange meetings.',
            'Responsible in planning and overseeing new marketing initiatives.',
            'Responsible in increasing the value of current customers while attracting new ones.',
            'Responsible in attending conferences, meetings, and industry events.',
            'Responsible in developing quotes and proposals for clients.',
            'Responsible in developing goals for the development of the team and business growth and ensuring they are met.',
            'Responsible in coaching and mentoring'
        ]
    }
]

export default CareerList;