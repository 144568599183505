import React from 'react';

import './style.scss';

import { Box, Typography } from '@mui/material';

import Fade from 'react-reveal/Fade';

import Heading from '../../../components/Heading';

// images
import FullTruckLoad from '../../../assets/img/land-transportation-full-truck-load.jpg';
import LessThanTruckLoad from '../../../assets/img/land-transportation-less-than-truck-load.jpg';
import InterIsland from '../../../assets/img/land-transportation-inter-island-transportation.jpg';

import {useTranslation} from 'react-i18next';

const LandTransportation = () => {
    const {t} = useTranslation();
    
    return (
        <Box component={'div'} className='land-transportation'>
            <Fade>
                <div>
                    <Heading
                        subTitle={t('Our Services')}
                        title={t('Land Transportation Services')}
                        dot
                    />

                    <Typography variant='h6' className='lt-subtitle'>
                        {t('Our expertise in land transportation includes Full Truck Load, Less than Truck Load and Inter-island Transportation.')}
                    </Typography>
                </div>
            </Fade>

            <Box component={'div'} className='lt-land-transportation-desc-cont'>
                <Fade>
                    <Box component={'div'} className='lt-desc'>
                        <Box component={'div'}>
                            <Heading title={t('Full Truck Load')} dot/>
                            <Typography variant='h6'>
                                {t('Any truck type is available, from closed van, open truck, up to tractor head. Your shipments with large quantity will be carried out in a dedicated truck for delivery. FTL shipments get to the destination faster and less handling; safety and security are also being observed and well-implemented. We can process the delivery and truck dispatch at your earliest convenience.')}
                            </Typography>
                        </Box>
                        <img src={FullTruckLoad} alt='' />
                    </Box>
                </Fade>
                <Fade>
                    <Box component={'div'} className='lt-desc'>
                        <Box component={'div'}>
                            <Heading title={t('Less than Truck Load')} dot/>
                            <Typography variant='h6'>
                                {t('Aside from bulk, we are also catering less than truck load (LTL) deliveries. LTL is costeffective and very ideal for small businesses or small shipment quantity. LTL only pay the cost for the portion the shipment consumed or occupied. Our big data will be responsible in analyzing the route and possible shippers for sharing. Easier and faster transactions are now accessible!')}
                            </Typography>
                        </Box>
                        <img src={LessThanTruckLoad} alt='' />
                    </Box>
                </Fade>
                <Fade>
                    <Box component={'div'} className='lt-desc'>
                        <Box component={'div'} style={{maxWidth: '53%'}}>
                            <Heading title={t('Inter-island Transportation')} dot/>
                            <Typography variant='h6'>
                                {t('Our wide network leads the solution for inter-island transport. Farther locations can now be reached through this kind of service. Proper handling and documentation will also being cared for as needed. Nationwide delivery can be outstretched as we are offering door to door delivery services.')}
                            </Typography>
                        </Box>
                        <img src={InterIsland} alt='' />
                    </Box>
                </Fade>
            </Box>
        </Box>
    )
}

export default LandTransportation;
