import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';

import './style.scss';

import { Box, Typography, Divider, List, ListItem, ListItemText, ListItemIcon, Chip, IconButton } from '@mui/material';

import { AccountCircle, Circle, Favorite } from '@mui/icons-material';

import CareerList from '../../../assets/constants/career-list';

import Heading from '../../../components/Heading';

import moment from 'moment';

import SocialList from '../../../assets/constants/social-list';

const CareerItem = () => {
    const {position} = useParams();

    const [career, setCareer] = useState(null);
    const [likeCount, setLikeCount] = useState(0);

    useEffect(() => {
        const pos = decodeURIComponent(position.replace(/-/g,' '));
        const careerDet = CareerList.find(n => n.title.toLowerCase() === pos);
        setCareer(careerDet);

        // eslint-disable-next-line
    },[])

    return (
        <Box component={'div'} className='career-item'>
            <Box component={'div'} className='c-banner' style={career ? {backgroundImage: `url(${career.image})`} : {}}>
                <Heading title={career ? career.title : ''} />
                <Typography variant='subtitle1' className='c-career-date'>{career ? moment(new Date(career.date)).format('MMMM DD, YYYY') : ''}</Typography>
                <AccountCircle fontSize='large' className='c-icon'/>
                <Typography variant='subtitle1' className='c-career-email'>hr@inteluck.com</Typography>
                {/* <Box component={'div'} className='c-banner-overlay'/> */}
            </Box>

            <Box component={'div'} className='c-details'>
                <Heading title={'Job Specifications'} />
                <List>
                    {
                        career && career.specification.map((item, key) => (
                            <ListItem key={key} className='c-ul-list-item align-center'>
                                <ListItemIcon><Circle fontSize='small'/></ListItemIcon>
                                <ListItemText>{item}</ListItemText>
                            </ListItem>
                        ))
                    }
                </List>
                <Divider />
                <Heading title={'Job Responsibilities'} />
                <List>
                    {
                        career && career.responsibility.map((item, key) => (
                            <ListItem key={key} className='c-ul-list-item align-center'>
                                <ListItemIcon><Circle fontSize='small'/></ListItemIcon>
                                <ListItemText>{item}</ListItemText>
                            </ListItem>
                        ))
                    }
                </List>
                <Divider />
                <Heading title={'Office Address'} />
                <List>
                    <ListItem className='c-ul-list-item align-center'>
                        <ListItemIcon><Circle fontSize='small'/></ListItemIcon>
                        <ListItemText>107 Aguirre Bldg, Aguirre Street, Legaspi Village, Makati City</ListItemText>
                    </ListItem>
                </List>
                <Divider />
                <Heading title={'Submit Application'} />
                <List>
                    <ListItem className='c-ul-list-item align-center'>
                        <ListItemIcon><Circle fontSize='small'/></ListItemIcon>
                        <ListItemText>Interested in this position? Send your CV to <a href="mailto:hr@inteluck.com">hr@inteluck.com</a></ListItemText>
                    </ListItem>
                </List>
                {/* <Box component={'div'} className='c-career-like'>
                    <Chip icon={<Favorite />} label={`${likeCount} Likes`} />
                </Box> */}
                <Box component={'div'} className='c-career-socials'>
                    {
                        SocialList.map((item, key) => (
                            <IconButton key={key} size='large'>
                                {item.icon}
                                <a href={item.link} target='_blank' rel='noreferrer'>{item.name[0]}</a>
                            </IconButton>
                        ))
                    }
                </Box>
                {/* <Box component={'div'} className='c-career-comment'>
                    <Heading title={'0 Comments'} />
                </Box> */}
            </Box>
        </Box>
    )
}

export default CareerItem;