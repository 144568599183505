export function getUrlParams(name){
  var url = window.location.href;
  var params = url.match(/[?]/g) !== null ? url.split("?")[1] : "";

  if(params === null){
    return null;
  }else{
    var queryVal = "";
    var queryParams = params.split("&");
    queryParams.forEach(item => {
      var paramNameVal = item.split("=");
      if(paramNameVal[0] === name){
        queryVal = paramNameVal[1];
      }
    })

    if(queryVal !== ""){
      return queryVal;
    }else{
      return null;
    }
  }
}

export function getDomain(){
  const redirect = getUrlParams('redirect');
  const origin = redirect || window.location.host;
  return origin.substring(origin.indexOf("."));
}

export const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

// set cookies
export function setCookie({name, value}){
  document.cookie = `${name}=${value}; path=/; domain=${getDomain()}`;
}

// remove cookies
export function removeCookie({name}){
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${getDomain()}`;
}

// check if the component is inside viewport
export function isInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)

  );
}

// check if email is valid
export function ValidateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
    return (true);
  }

  return (false);
}

// check if device is mobile
export function detectMob() {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}