import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/scss/responsive.scss';
import App from './App';
import {createStore, applyMiddleware, compose} from 'redux';
import ROOT_REDUCERS from './reducers';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';

// redux dev tools
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    ROOT_REDUCERS,
    composeEnhancer(applyMiddleware(thunk))
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />  
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
