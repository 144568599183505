import React, {useState, useEffect} from 'react';

import { useSelector } from 'react-redux';

import './style.scss';

import { useParams } from 'react-router-dom';

import { Box, Tabs, Tab } from '@mui/material';

import Heading from '../../components/Heading';

import history from '../../history';

import * as Common from '../../assets/js/common';

import DataPrivacyPolicy from './DataPrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';

const infoMenu = ['Data Privacy Policy', 'Terms & Conditions'];

const Information = () => {
    const {type} = useParams();

    const pageLoader = useSelector(state => state.pageLoader);
    const countryCode = useSelector(state => state.country);
    const [selectedDocument, setSelectedDocument] = useState(0);

    const checkIfDeviceMobile = () => {
        if(Common.detectMob()){
            document.querySelector('.header').classList.remove('i-move-header');
        }else{
            document.querySelector('.header').classList.add('i-move-header'); 
        }
    }

    useEffect(() => {
        // add class to the header
        checkIfDeviceMobile();

        const selInfoMenu = infoMenu.findIndex(n => n.toLowerCase() === decodeURIComponent(type).replace(/-/g,' '));
        setSelectedDocument(selInfoMenu);

        history.listen((e) => {
            const path = e.pathname.split('/');
            const selInfoMenu = infoMenu.findIndex(n => n.toLowerCase() === decodeURIComponent(path[path.length - 1]).replace(/-/g,' '));
            setSelectedDocument(selInfoMenu);
        })

        window.addEventListener('resize', checkIfDeviceMobile, false);

        return () => {
            // remove class to the header upon exiting the page
            document.querySelector('.header').classList.remove('i-move-header');
            window.removeEventListener('resize', checkIfDeviceMobile);
        }
    },[])

    const handleChangeTab = (e, newValue) => {
        setSelectedDocument(infoMenu[newValue].toLowerCase());

        history.push({pathname: `/${countryCode}/information/${infoMenu[newValue].includes('&') ? encodeURIComponent(infoMenu[newValue].toLowerCase()) : infoMenu[newValue].toLowerCase().replace(/\s/g,'-')}`});
    }

    return (
        <Box component='div' className='information'>
            <Box component='div' className='i-banner'>
                {
                    selectedDocument === 0 && (
                        <Heading title={'Data Privacy Policy'} dot />
                    )
                }
                {
                    selectedDocument === 1 && (
                        <Heading title={'Terms & Conditions'} dot />
                    )
                }
                {/* <Box component='div' className='i-banner-overlay' /> */}
            </Box>
            <Box component={'div'} className='i-tab'>
                <Tabs value={selectedDocument} onChange={handleChangeTab}>
                    {
                        infoMenu.map((item, key) => (
                            <Tab key={key} label={item} />
                        ))
                    }
                </Tabs>
            </Box>
            {
                selectedDocument === 0 && (
                    <DataPrivacyPolicy />
                )
            }
            {
                selectedDocument === 1 && (
                    <TermsAndConditions />
                )
            }
        </Box>
    )
}

export default Information;