import React from 'react';

import { Twitter, Instagram, Facebook, LinkedIn } from '@mui/icons-material';

const SocialList = [
    {
        name: 'Twitter',
        link: 'https://twitter.com/Inteluck_Global',
        icon: <Twitter />
    },
    {
        name: 'Instagram',
        link: 'https://www.instagram.com/inteluck_global/',
        icon: <Instagram />
    },
    {
        name: 'Facebook',
        link: 'https://www.facebook.com/Inteluck/',
        icon: <Facebook />
    },
    {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/company/inteluck',
        icon: <LinkedIn />
    }
]

export default SocialList;