import React from "react";

import './style.scss';

import { Box, Typography } from "@mui/material";

const Heading = ({subTitle, title, dot, variant, className}) => {
    return (
        <Box component={'div'} className={`heading ${className || ''}`}>
            {
                subTitle && (
                    <Box component={'div'} className='h-sub-title'>
                        {subTitle}
                    </Box>
                )
            }
            <Box component={'div'} className='h-title'>
                <Typography textAlign={'center'} variant={variant || 'h3'} className={`${dot && 'dot'}`}>{title}</Typography>
            </Box>
        </Box>
    )
}

export default Heading;