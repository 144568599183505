import React from 'react';

import './style.scss';

import { Box, Typography } from '@mui/material';

import Fade from 'react-reveal/Fade';

import Heading from '../../../components/Heading';

import {useTranslation} from 'react-i18next';

// images
import DomesticAirOceanFreight from '../../../assets/img/air-ocean-freight-domestic-air-ocean-frieght.jpg';
import InternationalAirOceanFreight from '../../../assets/img/air-ocean-freight-international-air-ocean-freight.jpg';

const AirOceanFreight = () => {
    const {t} = useTranslation();

    return (
        <Box component={'div'} className='air-ocean-freight'>
            <Fade>
                <div>
                    <Heading
                        subTitle={t('Our Services')}
                        title={t('Air & Ocean Freight')}
                        dot
                    />

                    <Typography variant='h6' className='ao-subtitle'>
                        {t('Customized solutions for your specific Air & Ocean Freight requirement are provided. We are also experienced in handling Customs Clearance.')}
                    </Typography>
                </div>
            </Fade>
            
            <Box component={'div'} className='ao-air-ocean-desc-cont'>
                <Fade>
                    <Box component={'div'} className='ao-desc'>
                        <Box component={'div'}>
                            <Heading title={t('Domestic Air & Ocean Freight')} dot/>
                            <Typography variant='h6'>
                                {t('We provide a custom-made logistics services solution for our clients. Goods or deliveries can be transported via air or sea, depending on your requirement. Import/Export need are well executed by our Logistics Team. Our nature is to give an end to end solution just for you!')}
                            </Typography>
                        </Box>
                        <img src={DomesticAirOceanFreight} alt='' />
                    </Box>
                </Fade>
                <Fade>
                    <Box component={'div'} className='ao-desc'>
                        <Box component={'div'}>
                            <Heading title={t('International Air & Ocean Freight')} dot/>
                            <Typography variant='h6'>
                                {t('Our services’ flexibility leads us in being complete, accurate, and timely with documentation which is a crucial part for international transport. This ensures well-timed delivery for international air and ocean freight deliveries. Import/Export needs are also well executed by our Logistics Team. Our nature is to give an end to end solution just for you!')}
                            </Typography>
                        </Box>
                        <img src={InternationalAirOceanFreight} alt='' />
                    </Box>
                </Fade>
            </Box>
        </Box>
    )
}

export default AirOceanFreight;
