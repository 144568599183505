import React,{useState, useEffect} from 'react';

import { useSelector } from 'react-redux';

import './style.scss';

import {useParams} from 'react-router-dom';

import { Box, Tabs, Tab, Typography } from '@mui/material';

import Constant from '../../assets/constants/constant.json';

import GetAQuoteButton from '../../components/GetAQuoteButton';

import LandTransportation from './LandTransportation';
import AirOceanFreight from './AirOceanFreight';
import WarehouseStorage from './WarehouseStorage';
import LogisticsOptimization from './LogisticsOptimization';

import Heading from '../../components/Heading';

import history from '../../history';

import {useTranslation} from 'react-i18next';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {t} = useTranslation();
    const { children, value, index, ...other } = props;
    const services = Constant.menu.find(n => n.menu === 'Services');
    const serviceDesc = services['sub-menu'].find(n => n.menu === children);

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
            <>
                <Heading title={t(children)} dot variant={'h1'}/>
                <Typography variant='h6' className='s-service-desc'>
                    {t(serviceDesc.desc)}
                </Typography>
            </>
        )}
      </div>
    );
  }

const Services = () => {
    const {service} = useParams();
    const {t} = useTranslation();
    const countryCode = useSelector(state => state.country);

    const [serviceTab, setServiceTab] = useState(0);
    const [serviceListTab, setServiceListTab] = useState([]);
    const [serviceBanner, setServiceBanner] = useState('');

    useEffect(() => {
        const serviceMenu = Constant.menu.find(n => n.menu === 'Services');
        const subMenu = serviceMenu['sub-menu'];
        setServiceListTab(subMenu);

        let menu, menuVal;
        if(service) {
            const formatMenuInd = subMenu.findIndex(n => n.menu.toLowerCase().replace('&',' ').replace(/\s+/g,'-') === service);
            menu = subMenu[formatMenuInd].menu.toLowerCase().replace('&',' ').replace(/\s+/g,'-');
            menuVal = formatMenuInd;
        }else{
            menu = subMenu[0].toLowerCase().replace('&',' ').replace(/\s+/g,'-');
            menuVal = 0;
        }

        setServiceBanner(menu);
        setServiceTab(menuVal);

        history.listen((e) => {
            const location = e.pathname.substring(1).split('/');
            const newTabInd = subMenu.findIndex(n => n.menu.toLowerCase().replace('&',' ').replace(/\s+/g,'-') === location[location.length - 1]);

            setServiceBanner(location[location.length - 1]);
            setServiceTab(newTabInd);
        })
        // eslint-disable-next-line
    },[]);

    const handleChangeTab = (e, newValue) => {
        const menu = serviceListTab[newValue].menu.toLowerCase().replace('&',' ').replace(/\s+/g,'-');
        setServiceBanner(menu);
        setServiceTab(newValue);

        history.push({pathname: `/${countryCode}/services/${menu}`});
    };

    const getServicePage = (num) => {
        switch(num) {
            case 0:
                return <LandTransportation />
            case 1:
                return <AirOceanFreight />
            case 2:
                return <WarehouseStorage />
            case 3:
                return <LogisticsOptimization />
            default:
                break;
        }
    }

    return (
        <Box component={'div'} className='services'>
            <Box component={'div'} className={`s-banner ${serviceBanner}`}>
                {
                    serviceListTab.map((item, key) => (
                        <TabPanel key={key} value={serviceTab} index={key} className='s-banner-text'>
                            {item.menu}
                        </TabPanel>
                    ))
                }
                {/* <Box component={'div'} className='s-banner-overlay' /> */}
                <Box component={'div'} className='s-tab'>
                    <Tabs value={serviceTab} onChange={handleChangeTab}>
                        {
                            serviceListTab.map((item, key) => (
                                <Tab key={key} label={t(item.menu)} />
                            ))
                        }
                    </Tabs>
                </Box>
            </Box>
            {getServicePage(serviceTab)}

            <GetAQuoteButton />
        </Box>
    )
}

export default Services;