import React, {useEffect, useState} from 'react';

import { useSelector } from 'react-redux';

// style
import './style.scss';

// component
import { Grid, MenuItem, Typography, Box, List, ListItem, ListItemIcon, ListItemText, Button, Drawer, IconButton, Collapse } from '@mui/material';

// icons
import { KeyboardArrowDown, Menu, Close, KeyboardArrowUp } from '@mui/icons-material';

// logo
import Logo from '../../assets/img/inteluck-logo-2020.png';

// menu constant
import constant from '../../assets/constants/constant.json';
import CountryList from '../../assets/constants/country-list';

import history from '../../history';

import {useTranslation} from 'react-i18next';

const Header = () => {
    const {t} = useTranslation();

    const countryCode = useSelector(state => state.country);
    const [activeMenu, setActiveMenu] = useState(`/${countryCode}`);
    const [country, setCountry] = useState('Singapore');
    const [openDrawer, setOpenDrawer] = useState({left: false});
    const [collapsableMenu, setCollapsableMenu] = useState({
        services: false,
        country: false
    })

    useEffect(() => {
        // initialize the scrolling effect
        window.onscroll = handleChangeHeaderStyle;

        if(window.location.pathname !== `/${countryCode}` && window.location.pathname !== `/${countryCode}/` && window.location.pathname.includes('/careers/') === false){
            document.getElementsByClassName("header")[0].classList.add('h-scrolled');

            if(window.location.pathname.includes('/services/')){
                setActiveMenu(`/${countryCode}/services`);
            }else{
                setActiveMenu(window.location.pathname);
            }
        }else{
            if(window.location.pathname === `/${countryCode}/`){
                setActiveMenu(`/${countryCode}/`);
                // history.replace({pathname: `/${countryCode}/`});
            }else{
                const servicePath = window.location.pathname.match(/\/\D+\//)[0];
                setActiveMenu(servicePath.substring(0,servicePath.length - 1));
            }
        }

        history.listen((e) => {
            if(e.pathname.substring(1, e.pathname.length - 1).split('/').length !== 1 && e.pathname.includes('/careers/') === false){
                document.getElementsByClassName("header")[0].classList.add('h-scrolled');
            }else{
                document.getElementsByClassName("header")[0].classList.remove('h-scrolled');
            }

            if(e.pathname.includes('/services/')){
                setActiveMenu(`/${countryCode}/services`);
            }else{
                if(e.pathname.includes('/careers/')){
                    setActiveMenu(`/${countryCode}/careers`);
                }else{
                    setActiveMenu(e.pathname);
                }
                
            }
        })
        // eslint-disable-next-line
    },[]);

    useEffect(() => {
        const details = CountryList.find(n => n.code === countryCode);
        setCountry(details.name);
    },[countryCode]);

    const handleChangeHeaderStyle = () => {
        if(window.location.pathname.substring(1,window.location.pathname.length - 1).split('/').length === 1 || window.location.pathname.includes('/careers/')){
            if (document.documentElement.scrollTop > 0) {
                document.getElementsByClassName("header")[0].classList.add('h-scrolled');
            } else {
                document.getElementsByClassName("header")[0].classList.remove('h-scrolled');
            }
        }
    }

    const handleRedirectToPage = (e, url) => {
        if(e !== undefined) e.preventDefault();
        let path = url;
        if(url.includes('services')){
            const services = constant.menu.find(n => n.menu === 'Services');
            const services1 = services['sub-menu'][0];
            path = services1.url;
        }
        
        path = path === '' ? '/' : path;
        setOpenDrawer({left: false});
        history.push({pathname: `/${countryCode}${path}`});
    }

    const handleServiceRedirect = (e, url) => {
        e.stopPropagation();
        setOpenDrawer({left: false});
        setCollapsableMenu({services: false, country: false});
        history.push({pathname: `/${countryCode}${url}`});
    }

    const handleRedirectLogin = (type) => {
        history.push({pathname: `/${countryCode}/login/${type}`});
    }

    const handleRedirectSignUp = (type) => {
        setOpenDrawer({left: false});
        setCollapsableMenu({services: false, country: false});

        history.push({pathname: `/${countryCode}/sign-up/${type}`});
    }

    const getCountryFlag = (country) => {
        const flag = CountryList.find(n => n.name === country);
        return flag.image;
    }

    const handleChangeCountry = (country) => {
        const countryDetails = CountryList.find(n => n.name === country);
        document.cookie = `web-country=${countryDetails.code}; path=/;`;

        setCollapsableMenu({...collapsableMenu, country: false});

        const url = `${window.location.origin}/${countryDetails.code}${window.location.pathname.substring(3)}`;
        window.location.href = url;
    }

    const handleDrawerRedirect = (obj) => {
        if(obj.menu === 'Services'){
            const collapsableMenu2 = {...collapsableMenu};

            collapsableMenu2.services = !collapsableMenu2.services;
            collapsableMenu2.country = false;

            setCollapsableMenu(collapsableMenu2);
        }else{
            handleRedirectToPage(undefined, obj.url);
        }
    }
    
    const handleOpenCountryDrawerList = () => {
        const collapsableMenu2 = {...collapsableMenu};

        collapsableMenu2.services = false;
        collapsableMenu2.country = !collapsableMenu2.country;

        setCollapsableMenu(collapsableMenu2);
    }

    return (
        <div className="header">
            <Grid container spacing={1} className="h-root">
                <Grid item xs={12} className='align-center h-root-cont'>
                    <img src={Logo} alt='' className='h-logo' onClick={(e) => handleRedirectToPage(e,'/')}/>
                    <Box component={'div'} className='h-right-cont'>
                        <Box className='h-menu'>
                            {
                                constant.menu.map((item, key) => (
                                    <MenuItem key={key} className={`h-menu-item ${`/${countryCode}${item.url}` === activeMenu ? 'active' : ''}`}>
                                        <Typography textAlign="center" className='h-menu-item-text'>
                                            <a href={item.url} onClick={(e) => handleRedirectToPage(e,item.url)}>{t(item.menu)}</a>
                                        </Typography>
                                        {
                                            item['sub-menu'] && (
                                                <>
                                                    &nbsp;
                                                    <KeyboardArrowDown fontSize='10' className='h-sub-menu-arrow'/>
                                                    <div className='h-sub-menu'>
                                                        <div className='triangle'>
                                                            <div className="inner-triangle"></div>
                                                        </div>
                                                        {
                                                            item['sub-menu'].map((sub, key) => (
                                                                <Typography textAlign="center" key={key} onClick={(e) => handleServiceRedirect(e, sub.url)}>{t(sub.menu)}</Typography>
                                                            ))
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }
                                    </MenuItem>
                                ))
                            }
                            <MenuItem className='h-menu-item'>
                                <Typography textAlign="center" className='h-menu-item-text'>
                                    <img src={getCountryFlag(country)} alt='' style={{marginRight: '10px'}}/>
                                    {country}
                                </Typography>
                                <>
                                    &nbsp;
                                    <KeyboardArrowDown fontSize='10'/>
                                    <div className='h-sub-menu h-menu-country'>
                                        <div className='triangle'>
                                            <div className="inner-triangle"></div>
                                        </div>
                                        <List>
                                            {
                                                CountryList.map((sub, key) => (
                                                    sub.code !== 'CH' && (
                                                        <ListItem key={key} onClick={() => handleChangeCountry(sub.name)}>
                                                            <ListItemIcon>
                                                                <img src={sub.image} alt=''/>
                                                            </ListItemIcon>
                                                            <ListItemText>
                                                                <Typography textAlign="center" key={key}>{sub.name}</Typography>
                                                            </ListItemText>
                                                        </ListItem>
                                                    )
                                                ))
                                            }
                                        </List>
                                    </div>
                                </>
                            </MenuItem>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <div className='mobile-navigation'>
                <Button variant='contained' className='h-hamburger' disableElevation onClick={() => setOpenDrawer({...openDrawer, left: true})}>
                    <Menu />
                </Button>
                <Drawer
                    anchor={'left'}
                    open={openDrawer.left}
                    onClose={() => setOpenDrawer({...openDrawer, left: false})}
                >
                    <Box component={'div'} className='h-mobile-drawer'>
                        <Typography variant='h6' className='drawer-title'>
                            <span>Menu</span>
                            <IconButton onClick={() => setOpenDrawer({...openDrawer, left: false})}>
                                <Close />
                            </IconButton>
                        </Typography>
                        <List>
                            {
                                constant.menu.map((item,key) => (
                                    <>
                                        <ListItem key={key} button onClick={() => handleDrawerRedirect(item)} className='drawer-menu-li'>
                                            <ListItemText>{item.menu}</ListItemText>
                                            {
                                                item['sub-menu'] && (
                                                    <ListItemIcon>
                                                        {
                                                            collapsableMenu[item.menu.toLowerCase()] ? (
                                                                <KeyboardArrowUp />
                                                            ) : (
                                                                <KeyboardArrowDown />
                                                            )
                                                        }
                                                    </ListItemIcon>
                                                )
                                            }
                                        </ListItem>
                                        {   
                                            item['sub-menu'] && (
                                                <Collapse in={collapsableMenu[item.menu.toLowerCase()]}>
                                                    <List>
                                                        {
                                                            item['sub-menu'].map((sub, key) => (
                                                                <ListItem key={key} button onClick={(e) => handleServiceRedirect(e, sub.url)}>
                                                                    <ListItemText>{sub.menu}</ListItemText>
                                                                </ListItem>
                                                            ))
                                                        }
                                                    </List>
                                                </Collapse>
                                            )
                                        }
                                    </>
                                ))
                            }
                            <ListItem button onClick={handleOpenCountryDrawerList} className='drawer-menu-li drawer-flag'>
                                <ListItemText>
                                    <img src={getCountryFlag(country)} alt='' style={{marginRight: '10px'}}/>
                                    {country}
                                </ListItemText>
                                <ListItemIcon>
                                    {
                                        collapsableMenu.country ? (
                                            <KeyboardArrowUp />
                                        ) : (
                                            <KeyboardArrowDown />
                                        )
                                    }
                                </ListItemIcon>
                            </ListItem>
                            <Collapse in={collapsableMenu.country}>
                                <List>
                                    {
                                        CountryList.map((sub, key) => (
                                            sub.code !== 'CH' && (
                                                <ListItem key={key} onClick={() => handleChangeCountry(sub.name)}>
                                                    <ListItemText>
                                                        <img src={sub.image} alt='' style={{marginRight: '10px'}}/>
                                                        {sub.name}
                                                    </ListItemText>
                                                </ListItem>
                                            )
                                        ))
                                    }
                                </List>
                            </Collapse>
                            <ListItem>
                                <ListItemText>
                                    <Button variant='contained' disableElevation className='button green' onClick={() => handleRedirectSignUp('shipper')} style={{width: '100%'}}>I'm a Shipper</Button>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Button variant='contained' disableElevation className='button orange' onClick={() => handleRedirectSignUp('carrier')} style={{width: '100%'}}>I'm a Carrier</Button>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Box>
                </Drawer>
            </div>
        </div>
    )
}

export default Header;