import React from 'react';

import './style.scss';

import { Box, Typography } from '@mui/material';

import Fade from 'react-reveal/Fade';

import Heading from '../../../components/Heading';

import {useTranslation} from 'react-i18next';

// images
import WarehousingCargoStorage from '../../../assets/img/warehouse-storage-warehousing-cargo-storage.jpg';

const WarehouseStorage = () => {
    const {t} = useTranslation();

    return (
        <Box component={'div'} className='warehouse-storage'>
            <Fade>
                <div>
                    <Heading
                        subTitle={t('Our Services')}
                        title={t('Warehouse & Storage')}
                        dot
                    />

                    <Typography variant='h6' className='ws-subtitle'>
                        {t('You will be freed by the simultaneous tasks if you still handle your own storage needs and supply chain duties. We provide a full-featured warehousing and storage for smooth transaction and movement of your cargoes.')}
                    </Typography>
                </div>
            </Fade>
            
            <Box component={'div'} className='ws-warehouse-storage-desc-cont'>
                <Fade>
                    <Box component={'div'} className='ws-desc'>
                        <Box component={'div'}>
                            <Heading title={t('Warehousing & Cargo Storage')} dot/>
                            <Typography variant='h6'>
                                {t('Our available facilities will support you to have a greater speed, productive transportation and supply chain. We proud ourselves in providing quality services for proper handling and secure storage of your cargoes.')}
                            </Typography>
                        </Box>
                        <img src={WarehousingCargoStorage} alt='' />
                    </Box>
                </Fade>
            </Box>
        </Box>
    )
}

export default WarehouseStorage;
