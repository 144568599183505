import React,{ useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import './style.scss';

import { Box, Slide, Button, IconButton } from '@mui/material';

import { Close } from '@mui/icons-material';

import history from '../../history';

const CookieNotice = () => {

    const countryCode = useSelector(state => state.country);

    const [showNotice, setShowNotice] = useState(false);

    useEffect(() => {
        const userCookie = localStorage.getItem('inteluck-accept-cookie');
        console.info(userCookie);
        setShowNotice(userCookie ? false : true);
    },[]);

    const handleRedirectToPrivacyPolicy = () => {
        history.push({pathname: `/${countryCode}/information/data-privacy-policy`});
    }

    const handleAcceptCookies = () => {
        localStorage.setItem('inteluck-accept-cookie', true);
        setShowNotice(false);
    }

    return (
        <Slide in={showNotice} direction='up'>
            <Box component={'div'} className='cookie-notice'>
                <Box component={'div'} className='cn-inner'>
                    <span>
                        We use cookies to make sure the website can function to measure traffic and to support the marketing of our services by using the website.
                        Please read our <span className='orange-txt' onClick={handleRedirectToPrivacyPolicy}>Data Privacy Policy</span> for further information about our use of cookies.
                    </span>
                    <Button variant='contained' className='button green' disableElevation onClick={handleAcceptCookies}>I Understand</Button>
                    <IconButton className='cn-close-button' onClick={() => setShowNotice(false)}>
                        <Close />
                    </IconButton>
                </Box>
            </Box>
        </Slide>
    )
}

export default CookieNotice