import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import './style.scss';

import { Box, Typography, Grid, TextField, Button, List, ListItem, ListItemIcon, ListItemText, Avatar, Chip, Collapse, InputAdornment } from '@mui/material';

import { ArrowForward, LocationOnOutlined, EmailOutlined, LocalPhone, KeyboardArrowLeft, KeyboardArrowRight, PriorityHigh } from '@mui/icons-material';

import Heading from '../../components/Heading';

import Fade from 'react-reveal/Fade';

// for slider
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// social links
import SocialList from '../../assets/constants/social-list';

import {useTranslation} from 'react-i18next';

// import country address
import CountriesOffice from '../../assets/constants/country-list';

import * as Common from '../../assets/js/common';
import inteluck from '../../apis/inteluck';

const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

let officeMarker, sliderRef = React.createRef();
const ContactUs = () => {
    const {t} = useTranslation();

    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();

    const countryCode = useSelector(state => state.country);
    const [disableFields, setDisableFields] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [openToast, setOpenToast] = useState({show: false, message: '', severity: ''});

    const [map, setMap] = useState(null);

    const [mapCenter, setMapCenter] = useState(null);
    const [sliderDefault, setSliderDefault] = useState();

    useEffect(() => {
        const currentSelectedCountry = CountriesOffice.find(n => n.code === countryCode);
        const countryIndex = CountriesOffice.findIndex(n => n.code === countryCode);
        setMapCenter(currentSelectedCountry.location);
        setSliderDefault(countryIndex);

        const prop = {
            center: currentSelectedCountry.location,
            zoom: 11,
            mapTypeControl: false,
            draggable: true,
            scrollwheel: false,
            zoomControl: true,// hide zoom control
            streetViewControl: false,
            panControl: false,
            scaleControl: true,
            overviewMapControl: false,
            fullscreenControl: true,
            rotateControl: false,
            minZoom: 4,
            maxZoom: 18,
          };
          const onLoad = () => setMap(new window.google.maps.Map(document.getElementById('map'), prop))
          if (!window.google) {
            const script = document.createElement(`script`)
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`
            document.head.append(script)
            script.addEventListener(`load`, onLoad)
            return () => script.removeEventListener(`load`, onLoad)
          } else onLoad();
          // eslint-disable-next-line
    },[]);



    useEffect(() => {
        if(map){
            if(officeMarker){
                officeMarker.setPosition(mapCenter);
                map.panTo(mapCenter);
                map.setZoom(11);
            }else{
                officeMarker = new window.google.maps.Marker({
                    map,
                    position: mapCenter
                })
            }
        }
    },[map, mapCenter]);

    const handlePanToOffice = (e) => {
        const countryOffice = CountriesOffice.find(n => n.country === e.target.alt);
        setMapCenter(countryOffice.location);
    }

    const handleSubmitInquiry = data => {
        // check if email is in the correct format
        if(!Common.ValidateEmail(data.email)){
            setInvalidEmail(true);
            return
        }

        setDisableFields(true);
        setInvalidEmail(false);
        setOpenToast({show: true, message: 'Please wait...', severity: 'info'});
        inteluck.post('tools/Feedback/Contact',data)
        .then(res => {
            if(res.status === 200){
                Object.keys(data).forEach(item => {
                    setValue(item, '');
                })

                setDisableFields(false);
                setOpenToast({show: true, message: 'Thank you for your inquiry', severity: 'success'});
                setTimeout(() => {
                    setOpenToast({...openToast, show: false});
                }, 2000);
            }
        })
        .catch(err => {
            setOpenToast({show: true, message: 'Something went wrong', severity: 'error'});
        })
    };

    return (
        <Box component={'div'} className='contact-us'>
            <Box id='map' component={'div'} className='c-map' />
            <Box component={'div'} className='c-help-center'>
                {
                    CountriesOffice.map((item, key) => (
                        <Box key={key} component={'div'} className='c-help-items'>
                            <img src={item.flag} alt={item.country} onClick={handlePanToOffice}/>
                            <Heading title={item.country} variant='h5' dot/>
                            <Typography variant='subtitle1' className='c-help-address'>{item.address}</Typography>
                            <a href={`https://www.google.com/maps/search/${item.address}`}>
                                {t('Help Center')} &nbsp;
                                <ArrowForward  fontSize='small'/>
                            </a>
                        </Box>
                    ))
                }
            </Box>
            <Box component={'div'} className='c-inquiry-section'>
                <Heading title={t('We take care about transportation for your business')} />
                <Fade>
                    <Typography variant='subtitle1' className='c-inquiry-section-subtitle'>
                        {t('We would be glad to assist you further with your queries, Please drop us a message!')}
                    </Typography>
                </Fade>
                
                <Fade>
                    <Box component={'div'} className='c-inquiry-cont'>
                        <Box component={'div'} className='c-inquiry-left'>
                            <Heading title={t('Need to Make an Inquiry?')} variant='h4'/>
                            <form onSubmit={handleSubmit(handleSubmitInquiry)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField 
                                            variant="outlined" 
                                            placeholder={t('Company')} 
                                            autoComplete='off'
                                            {...register('company', {required: true})}
                                            InputProps={
                                                {
                                                    endAdornment: errors.company && <InputAdornment><PriorityHigh style={{color: 'red'}}/></InputAdornment>
                                                }
                                            }
                                            disabled={disableFields}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField 
                                            variant="outlined" 
                                            placeholder={t('First Name')} 
                                            autoComplete='off'
                                            {...register('firstname', {required: true})}
                                            InputProps={
                                                {
                                                    endAdornment: errors.firstname && <InputAdornment><PriorityHigh style={{color: 'red'}}/></InputAdornment>
                                                }
                                            }
                                            disabled={disableFields}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField 
                                            variant="outlined" 
                                            placeholder={t('Last Name')} 
                                            autoComplete='off'
                                            {...register('lastname', {required: true})}
                                            InputProps={
                                                {
                                                    endAdornment: errors.lastname && <InputAdornment><PriorityHigh style={{color: 'red'}}/></InputAdornment>
                                                }
                                            }
                                            disabled={disableFields}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined" 
                                            placeholder={t('Mobile/Phone Number')}
                                            autoComplete='off'
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*'
                                            }}
                                            {...register('contact', {required: true})}
                                            InputProps={
                                                {
                                                    endAdornment: errors.contact && <InputAdornment><PriorityHigh style={{color: 'red'}}/></InputAdornment>
                                                }
                                            }
                                            disabled={disableFields}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField 
                                            variant="outlined" 
                                            placeholder={t('Email')} 
                                            autoComplete='off'
                                            {...register('email', {required: true})}
                                            InputProps={
                                                {
                                                    endAdornment: (errors.email || invalidEmail) && <InputAdornment><PriorityHigh style={{color: 'red'}}/></InputAdornment>
                                                }
                                            }
                                            disabled={disableFields}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField 
                                            variant="outlined" 
                                            placeholder={t('Message')}
                                            autoComplete='off'
                                            multiline
                                            rows={11}
                                            {...register('message', {required: true})}
                                            InputProps={
                                                {
                                                    endAdornment: errors.message && <InputAdornment><PriorityHigh style={{color: 'red'}}/></InputAdornment>
                                                }
                                            }
                                            disabled={disableFields}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant='contained' className='button orange btn-send' disableElevation type='submit' disabled={disableFields}>{t('Send')}</Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Collapse in={openToast.show}>
                                            <Box component={'div'} className={`toast ${openToast.severity}`}>
                                                {openToast.message}
                                            </Box>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                        <Box component={'div'} className='c-inquiry-right'>
                            <Heading title={t('Get in Touch With Us')} variant='h3'/>
                            <Box component={'div'} className='c-slider'>
                                <Slider ref={sliderRef} {...sliderSettings} className='c-slider-cont' initialSlide={sliderDefault}>
                                    {
                                        CountriesOffice.map((item, key) => (
                                            <div className='c-slider-item' key={key + 1}>
                                                <Heading title={item.country} variant='h4' />
                                                <List className='c-slider-item-list'>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <Avatar sizes='small'>
                                                                <LocationOnOutlined fontSize='small'/>
                                                            </Avatar>
                                                        </ListItemIcon>
                                                        <ListItemText>{item.address}</ListItemText>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <Avatar sizes='small'>
                                                                <EmailOutlined fontSize='small'/>
                                                            </Avatar>
                                                        </ListItemIcon>
                                                        <ListItemText>support@inteluck.com</ListItemText>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <Avatar sizes='small'>
                                                                <LocalPhone fontSize='small'/>
                                                            </Avatar>
                                                        </ListItemIcon>
                                                        <ListItemText>{item.mobile}</ListItemText>
                                                    </ListItem>
                                                </List>
                                            </div>
                                        ))
                                    }
                                </Slider>
                                <Box component={'div'} className='c-slider-nav-cont'>
                                    <KeyboardArrowLeft className='c-nav' onClick={() => sliderRef.current.slickPrev()}/>
                                    <span>|</span>
                                    <KeyboardArrowRight className='c-nav' onClick={() => sliderRef.current.slickNext()}/>
                                </Box>
                            </Box>
                            <Box component={'div'} className='c-socials'>
                                {
                                    SocialList.map((item, key) => (
                                        <Box key={key} component={'div'} className='c-social-item'>
                                            <Chip icon={item.icon} label={item.name} />
                                            <a href={item.link} target='_blank' rel='noreferrer'>{item.name}</a>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Box>
        </Box>
    )
}

export default ContactUs;