import countryReducer from './countryReducer';
import pageLoaderReducer from './pageLoaderReducer';
import distanceWeightReducer from './distanceWeightReducer';
import {combineReducers} from 'redux';

const ROOT_REDUCERS = combineReducers({
    country: countryReducer,
    pageLoader: pageLoaderReducer,
    distanceWeight: distanceWeightReducer
});

export default ROOT_REDUCERS;