import React,{useState,useEffect} from 'react';

import './style.scss';

import { Box, Grid, Typography } from '@mui/material';

import Fade from 'react-reveal/Fade';

import Heading from '../../components/Heading';

// for slider
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// lightbox
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// images
import LifeAtInteluck1 from '../../assets/img/life-at-inteluck-1.jpg';
import LifeAtInteluck2 from '../../assets/img/life-at-inteluck-2.jpeg';
import LifeAtInteluck3 from '../../assets/img/life-at-inteluck-3.jpg';

import CorporateEvent1 from '../../assets/img/corporate-event-1.jpg';
import CorporateEvent2 from '../../assets/img/corporate-event-2.jpg';
import CorporateEvent3 from '../../assets/img/corporate-event-3.jpg';

import TeamActivity1 from '../../assets/img/team-activity-1.jpg';
import TeamActivity2 from '../../assets/img/team-activity-2.jpg';
import TeamActivity3 from '../../assets/img/team-activity-3.jpg';
import TeamActivity4 from '../../assets/img/team-activity-4.jpg';
import TeamActivity5 from '../../assets/img/team-activity-5.jpg';
import TeamActivity6 from '../../assets/img/team-activity-6.jpg';

import SocialResponsibility1 from '../../assets/img/social-responsibility-1.jpg';
import SocialResponsibility2 from '../../assets/img/social-responsibility-2.jpg';
import SocialResponsibility3 from '../../assets/img/social-responsibility-3.jpg';
import SocialResponsibility4 from '../../assets/img/social-responsibility-4.jpg';
import SocialResponsibility5 from '../../assets/img/social-responsibility-5.jpg';
import SocialResponsibility6 from '../../assets/img/social-responsibility-6.jpg';

import CareerList from '../../assets/constants/career-list';

// career card
import CareerCardItem from '../../components/CareerCardItem';

import {useTranslation} from 'react-i18next';

const LifeAtInteluckImages = [LifeAtInteluck3,LifeAtInteluck2,LifeAtInteluck1];
const CorporateEventImages = [CorporateEvent1,CorporateEvent2,LifeAtInteluck1,CorporateEvent3,LifeAtInteluck2,LifeAtInteluck3];
const TeamActivityImages = [TeamActivity1,TeamActivity2,TeamActivity3,TeamActivity4,TeamActivity5,TeamActivity6];
const SocialResponsibilityImages = [SocialResponsibility1,SocialResponsibility2,SocialResponsibility3,SocialResponsibility4,SocialResponsibility5,SocialResponsibility6];

const sliderRef = React.createRef();

const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
};

const Careers = () => {
    const {t} = useTranslation();
    const [gallery, setGallery] = useState([]);
    const [galleryLightboxOpen, setGalleryLightboxOpen] = useState(false);
    const [galleryIndex, setGalleryIndex] = useState(0);
    const [careerVacantList, setCareerVacantList] = useState([]);
    const [sliderJobSettings, setSliderJobSettings] = useState({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        cssEase: "linear"
    })

    useEffect(() => {
        setCareerVacantList(CareerList);

        const browserWidth = getCurrentBrowserWidth();
        handleUpdateJobSliderSettings(browserWidth);

        window.onresize = () => {
            const reizedWidth = getCurrentBrowserWidth();
            handleUpdateJobSliderSettings(reizedWidth);
        }
    },[])

    useEffect(() => {
        if(galleryLightboxOpen === false){
            setGallery([]);
            setGalleryIndex(0);
        }
    },[galleryLightboxOpen]);

    const getCurrentBrowserWidth = () => {
        return parseFloat(window.getComputedStyle(document.getElementsByTagName('body')[0]).getPropertyValue("width").replace('px',''));
    }

    const handleUpdateJobSliderSettings = (width) => {
        const settings = {...sliderJobSettings};
        console.info(width);
        switch(width) {
            case 375:
            case 390:
            case 360:
            case 393:
            case 412:
            case 414:
            case 667:
            case 740:
            case 768:
            case 851:
            case 896:
            case 914:
            case 915:
                settings.slidesToShow = 1;
                settings.slidesToScroll = 1;
                break;
            case 780:
            case 812:
            case 820:
            case 844:
            case 912:
            case 926:
            case 1024:
            case 1104:
            case 1159:
            case 1135: 
            case 1176:    
            case 1180:
            case 1194:
            case 1263:
                settings.slidesToShow = 2;
                settings.slidesToScroll = 2;
                break;
            case 1280:
                settings.slidesToShow = 3;
                settings.slidesToScroll = 3;
                break;
            default:
                break;
        }

        setSliderJobSettings(settings);
    }

    const handleLifeAtInteluckLightBox = (num) => {
        setGallery(LifeAtInteluckImages);
        setGalleryIndex(num);
        setGalleryLightboxOpen(true);
    }

    const handleOpenCorporateEventLightBox = (num) => {
        setGallery(CorporateEventImages);
        setGalleryIndex(num);
        setGalleryLightboxOpen(true);
    }

    const handleOpenTeamActivityLightBox = (num) => {
        setGallery(TeamActivityImages);
        setGalleryIndex(num);
        setGalleryLightboxOpen(true);
    }

    const handleOpenSocialResponsibilityLightBox = (num) => {
        setGallery(SocialResponsibilityImages);
        setGalleryIndex(num);
        setGalleryLightboxOpen(true);
    }

    return (
        <Box component={'div'} className='careers'>
            <Box component={'div'} className='c-banner'>
                <Heading title={t('Join us now')} dot />
                <Box component={'div'} className='c-banner-overlay' />
            </Box>

            <Box component={'div'} className='c-content'>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Box component={'div'} className='c-desc c-first-desc'>
                                    <Heading title={t('Life at Inteluck')} dot />
                                    <Typography variant='h6' className='c-desc-para'>
                                        {t('At Inteluck, we are working together to shape a new logistics landscape. Our team is comprised of amazing humans from diverse backgrounds with one common mission: to grow business by driving logistics efficiency.')}
                                        <br />
                                        <br />
                                        {t('Every new hire at Inteluck shapes our future company, and that is why we care so much about bringing in great people, like you. We are humble and hungry to solve the most challenging and complex problems in the logistics industry. Here, you will work on mission critical endeavors with society-wide impact and build the technology that will transform the entire logistics industry. There is a lot to be done, but our team of hard-working, bright, and visionary individuals will empower you to learn, grow, and succeed.')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} className='justify-center c-mui-grid-carousel'>
                                <Box component={'div'} className='c-img-carousel'>
                                    <Slider ref={sliderRef} {...sliderSettings}>
                                        {
                                            LifeAtInteluckImages.map((step, index) => (
                                                <div key={index}>
                                                    <div className='c-carousel-item' onClick={() => handleLifeAtInteluckLightBox(index)}>
                                                        <img src={step} alt=''/>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className='c-grid-gallery-images'>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Grid container spacing={2} className='c-grid-images'>
                                    {
                                        CorporateEventImages.map((item, key) => (
                                            <Grid key={key} item xs={4}>
                                                <div className='c-gallery-item' style={{backgroundImage: `url(${item})`}} onClick={() => handleOpenCorporateEventLightBox(key)}/>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Box component={'div'} className='c-desc c-align-center'>
                                    <Heading title={t('Corporate Event')} dot />
                                    <Typography variant='h6' className='c-desc-para'>
                                        {t('We regularly hold corporate events to review the company’s progress and take care of employees’ well-being by promoting a productive, happy, and collaborative work environment.')}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className='c-grid-gallery-images'>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Box component={'div'} className='c-desc c-align-center'>
                                    <Heading title={t('Team Activity')} dot />
                                    <Typography variant='h6' className='c-desc-para'>
                                        {t('Employee Gatherings are held regularly to create synergy in each department, maintain the bonds among employees, and strengthen company values.')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2} className='c-grid-images'>
                                    {
                                        TeamActivityImages.map((item, key) => (
                                            <Grid key={key} item xs={4}>
                                                <div className='c-gallery-item' style={{backgroundImage: `url(${item})`}} onClick={() => handleOpenTeamActivityLightBox(key)}/>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className='c-grid-gallery-images'>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Grid container spacing={2} className='c-grid-images'>
                                    {
                                        SocialResponsibilityImages.map((item, key) => (
                                            <Grid key={key} item xs={4}>
                                                <div className='c-gallery-item' style={{backgroundImage: `url(${item})`}} onClick={() => handleOpenSocialResponsibilityLightBox(key)}/>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Box component={'div'} className='c-desc c-align-center'>
                                    <Heading title={t('Social Responsibility')} dot />
                                    <Typography variant='h6' className='c-desc-para'>
                                        {t('Our resourceful talent share the same strong beliefs and dedication to realizing our goals together; anyone, regardless who they are and where they are from, can contribute to our collective success.')}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Box component={'div'} className='cc-job-vacancy'>
                <Heading title={t('Are you ready to make your move?')} variant='h3'/>
                <Heading title={t('Join us now.')} variant='h3'/>
                <Fade>
                    <Grid container spacing={3} className='cc-job-grid-style'>
                        {
                            careerVacantList.map((item, key) => (
                                <Grid item xs={4} key={key}>
                                    <CareerCardItem item={item} />
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Box component={'div'} className='cc-job-slider-style'>
                        <Slider ref={sliderRef} {...sliderJobSettings}>
                            {
                                careerVacantList.map((item, index) => (
                                    <div key={index}>
                                        <CareerCardItem item={item} />
                                    </div>
                                ))
                            }
                        </Slider>
                    </Box>
                </Fade>
            </Box>

            {
                galleryLightboxOpen && (
                    <Lightbox
                        mainSrc={gallery[galleryIndex]}
                        nextSrc={gallery[(galleryIndex + 1) % gallery.length]}
                        prevSrc={gallery[(galleryIndex + gallery.length - 1) % gallery.length]}
                        onCloseRequest={() => setGalleryLightboxOpen(false)}
                        onMovePrevRequest={() =>
                            setGalleryIndex((galleryIndex + gallery.length - 1) % gallery.length)
                        }
                        onMoveNextRequest={() =>
                            setGalleryIndex((galleryIndex + 1) % gallery.length)
                        }
                    />
                )
            }
        </Box>
    )
}

export default Careers;