import React from 'react';

import SGFlag from '../img/sg-header-flag.png';
import PHFlag from '../img/ph-header-flag.png';
import THFlag from '../img/th-header-flag.png';
import CHFlag from '../img/ch-header-flag.png';

import PHFlagContact from '../img/ph-contact-flag.png';
import SGFlagContact from '../img/sg-contact-flag.png';
import CHFlagContact from '../img/ch-contact-flag.png';
import THFlagContact from '../img/th-contact-flag.png';

const CountryList = [
    {
        name : "Philippines",
        country: "Philippines",
        image : PHFlag,
        flag : PHFlagContact,
        code : "PH",
        location: new window.google.maps.LatLng(14.552650, 121.016910),
        address: '107 Aguirre Bldg, Aguirre Street, Legaspi Village, Makati City',
        mobile: '+63 2 77516649 / +63 927-234-5673'
    },
    {
        name : "Singapore",
        country: "Singapore",
        image : SGFlag,
        flag : SGFlagContact,
        code : "SG",
        location: new window.google.maps.LatLng(1.2938457, 103.7934252),
        address: '29 Media Circle 02-14/15, South Wing, Alice@Mediapolis, Singapore (138565)',
        mobile: '+65 6977 7220'
    },
    {
        name : "中文 (中国)",
        country: "China",
        image : CHFlag,
        flag : CHFlagContact,
        code : "CH",
        location: new window.google.maps.LatLng(22.5428622, 113.9492787),
        address: 'Room 360, Fulizhen Building, No. 1, Kefa Road, Science Park Community, Yuehai Street, Nanshan District, Shenzhen City',
        mobile: '09272345673'
    },
    {
        name : "ไทย",
        country: "Thailand",
        image : THFlag,
        flag : THFlagContact,
        code : "TH",
        location: new window.google.maps.LatLng(13.7568923, 100.5646743),
        address: 'CP Tower 2 (Fortune Town), 17th Floor, 1, Radchadapisek Road, Dindaeng, Dindaeng Bangkok 10400 THAILAND',
        mobile: '02-119-7574'
    }
];

export default CountryList;