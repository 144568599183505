import React from 'react';

import { useSelector } from 'react-redux';

import './style.scss';

import { Box, Typography, Button } from '@mui/material';

import Fade from 'react-reveal/Fade';

import Heading from '../Heading';

import history from '../../history';

import {useTranslation} from 'react-i18next';

const GetAQuoteButton = () => {
    const {t} = useTranslation();
    const countryCode = useSelector(state => state.country);

    const handleRedirectGetAQuote = () => {
        history.push({pathname: `/${countryCode}/get-a-quote`});
    }

    return (
        <Fade>
            <Box component={'div'}>
                <Heading subTitle={t('Contact Us')} title={t('Here to Help Your Business Needs')} dot/>

                <Typography variant='subtitle1' className='g-subtitle'>
                    {t('Stop worrying about your delivery problems. Focus on your business. Let us provide the support you deserve.')}
                </Typography>

                <Box component={'div'} className='g-button-cont'>
                    <Button variant='contained' className='button orange' disableElevation onClick={handleRedirectGetAQuote}>{t('Get a Quote')}</Button>
                </Box>
            </Box>
        </Fade>
    )
}

export default GetAQuoteButton;