import React, { useState, useEffect } from 'react';

// style
import './style.scss';

import { Box, Typography } from '@mui/material';

import News from '../../components/News';

import Heading from '../../components/Heading';

import GetAQuoteButton from '../../components/GetAQuoteButton';

import HorizontalTimeline from "react-horizontal-timeline";

// for slider
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// menu constant
import constant from '../../assets/constants/constant.json';

// sir Kevin image
import SK from '../../assets/img/Forbes-Kevin-Zhang-3.png';

import BannerImage from '../../assets/img/about-us-banner.jpg';

import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';

const companyYearTimeline = ["2014","2016","2018","2019","2020","2022"];

const sliderRef = React.createRef();
const AboutUs = () => {
    const [timelineSelected, setTimelineSelected] = useState({current: 0, prevIdx: -1});
    const [progressSpec, setProgressSpec] = useState([]);

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        cssEase: "linear",
        afterChange: (current) => {
            const prev = timelineSelected.current;
            setTimelineSelected({current, prevIdx: prev});
        }
    };

    useEffect(() => {
        const services = constant.menu.find(n => n.menu === 'Services');
        const arr = [];
        if(services){
            services['sub-menu'].forEach(item => {
                arr.push({
                    label: item.menu,
                    prog: item.prog
                })
            })

            setProgressSpec(arr);
        }
    },[])

    return (
        <Box component={'div'} className='about-us'>
            {/* <LazyLoadComponent>
                <Box component={'div'} className='a-banner'/>
            </LazyLoadComponent> */}
            <Box component={'div'} className='a-banner'/>
            {/* <img src={BannerImage} alt='' loading='lazy' style={{width: '100%'}}/> */}
            {/* <LazyLoadImage
                alt={''}
                height={'619.2px'}
                src={BannerImage} // use normal <img> attributes as props
                width={'100%'}
                effect='opacity' 
            /> */}
            <Box component={'div'} className='a-desc-by-sk'>
                <Box component={'div'} className='a-desc'>
                    <Heading
                        title={'Build the most efficient logistics world'}
                        dot
                    />
                    <Typography variant='subtitle1'>
                        Inteluck provides data-driven end to end logistics services to enterprises in Southeast Asia. The core of Inteluck is to maximize logistics efficiency by leveraging technology and data and creating value for both customers and vendors.
                        <br/>
                        <br/>
                        Inteluck supports a variety of logistics platform services, including domestic FTL (full truckload) transportation, warehouse management, freight forwarding, cross border transportation and other bespoke supply chain services
                        <br/>
                        <br/>
                        Headquartered in Singapore and operates in other Southeast Asian countries including the Philippines and Thailand, with offices in China and other regions.
                        <br/>
                        <br/>
                        Inteluck’s mission is to build the most efficient logistics world by connecting talented and responsible people driven by the data and technology.
                    </Typography>
                    <Box component={'div'} className='a-letter-footer'>
                        <Typography variant='subtitle1'>Kevin Zhang</Typography>
                        <Typography variant='subtitle1'>Founder & CEO</Typography>
                    </Box>
                </Box>
                <Box component={'div'} className='a-desc-img'>
                    <img src={SK} alt='' loading='lazy'/>
                    <div>
                        Photo source from <a href='https://www.forbes.com/sites/johnkang/2022/05/25/the-30-under-30-asia-2022-entrepreneurs-transforming-the-booming-logistics-industry/?ss=entrepreneurs&sh=eaf512c7c679' target='_blank'>Forbes</a>
                    </div>
                </Box>
            </Box>

            <Box component={'div'} className='a-company-timeline'>
                <Heading subTitle={'History'} title={'Our Journey'} dot/>
                <div className='timeline'>
                    <HorizontalTimeline
                        styles={{
                            background: "#f8f8f8",
                            foreground: "#FF7E00",
                            outline: "#b2b4b6"
                        }}
                        index={timelineSelected.current}
                        indexClick={(index) => {
                            const curIdx = timelineSelected.current;
                            sliderRef.current.slickGoTo(index);
                            setTimelineSelected({
                                current: index,
                                prevIdx: curIdx
                            })
                        }}
                        values={companyYearTimeline}
                        getLabel={(ind) => {
                            return new Date(ind).getFullYear();
                        }}
                    />
                </div>
                <Slider ref={sliderRef} {...sliderSettings} initialSlide={timelineSelected.current}>
                    <div className='a-timeline-item'>
                        <h2>Founded in <span>Philippines</span></h2>
                        <h5>2014</h5>
                    </div>
                    <div className='a-timeline-item'>
                        <h2>Series Pre-A - <span>1M USD</span> by Infinity Ventures Partners Expanding the IoT and transportation network cover the Philippines nationwide</h2>
                        <h5>2016</h5>
                    </div>
                    <div className='a-timeline-item'>
                        <h2>Enter Enterprises Logistics Services field  Registered and connected to over <span>10,000 various truck fleets</span></h2>
                        <h5>2018</h5>
                    </div>
                    <div className='a-timeline-item'>
                        <h2>Series A - <span>1.76M USD</span> by Future Capital and other entrepreneurs and family fund - Revenue increased 3.5X in 12 months</h2>
                        <h5>2019</h5>
                    </div>
                    <div className='a-timeline-item'>
                        <h2>Series Pre-B - <span>5M USD</span> Led by MindWorks Ventures, followed by Infinity Ventures Partners and Local families in SEA End to End 3PL Services Enter Thailand Market Revenue increased 10X in 36 months</h2>
                        <h5>2020</h5>
                    </div>
                    <div className='a-timeline-item'>
                        <h2>Series B - <span>15M USD</span> Led by Creo Capita, followed by East Ventures and Headline Asia Awarded the HIGH-GROWTH COMPANIES ASIA-PACIFIC 2022 and Growth Champion in the Philippines</h2>
                        <h5>2022</h5>
                    </div>
                </Slider>
            </Box>

            <Box component={'div'} className="a-company-specs">
                <Heading title={'Optimizing your logistics chain'} dot/>
                <Box component={'div'} className="a-spec-progress">
                    {
                        progressSpec.map((item, key) => (
                            <div className='spec' key={key}>
                                <label>{item.label}</label>
                                <div className='track'>
                                    <span className='track-value-label' style={{left: `${item.prog - 2}%`}}>{`${item.prog}%`}</span>
                                    <span className='track-value' style={{width: `${item.prog}%`}}></span>
                                    <span className='track-ball' style={{left: `${item.prog - 1}%`}}></span>
                                </div>
                            </div> 
                        ))
                    }
                </Box>
            </Box>

            <Heading subTitle={'Media'} title={'Inteluck in the News'} dot/>

            <News />

            <GetAQuoteButton />
        </Box>
    )
}

export default AboutUs;