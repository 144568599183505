import React, {useState, useEffect} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Typography, Button, Box, Grid } from '@mui/material';

import { ArrowForward, ArrowBack } from '@mui/icons-material';

import Fade from 'react-reveal/Fade';

// for slider
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Odometer from 'react-odometerjs';
import "odometer/themes/odometer-theme-default.css";

// style
import './style.scss';

// banner images
import Banner1 from '../../assets/img/Inteluck-Website-Banner-1.jpg';
import Banner2 from '../../assets/img/Inteluck-Website-Banner-2.jpg';
import Banner3 from '../../assets/img/Inteluck-Website-Banner-3.jpg';

// icon services
import Services1 from '../../assets/img/icon-services-1.png';
import Services2 from '../../assets/img/icon-services-2.png';
import Services3 from '../../assets/img/icon-services-3.png';
import Services4 from '../../assets/img/icon-services-4.png';

// are you a trucker/shipper
import AreYouAShipper from '../../assets/img/are-you-a-shipper-banner.jpg';
import AreYouAShipper2 from '../../assets/img/are-you-a-shipper-banner-2.jpg';
import AreYouATrucker from '../../assets/img/are-you-a-trucker-banner.jpg';
import AreYouATrucker2 from '../../assets/img/are-you-a-trucker-banner-2.jpg';

// accomplishments images
import Accomplishment1 from '../../assets/img/icon-accomplishments-1.png';
import Accomplishment2 from '../../assets/img/icon-accomplishments-2.png';
import Accomplishment3 from '../../assets/img/icon-accomplishments-3.png';

// news component
import News from '../../components/News';

// award images
import Award from '../../assets/img/awards-small.png';

import Graph from '../../assets/img/graph.png';

// heading
import Heading from '../../components/Heading';

import history from '../../history';

import inteluck from '../../apis/inteluck';

import moment from 'moment';

import * as Common from '../../assets/js/common';

import {useTranslation} from 'react-i18next';

import { SHOW_PAGE_LOADER } from '../../action';

// import { LazyLoadImage } from 'react-lazy-load-image-component';

const images = [
    {
        "label": "Build the most efficient logistics world",
        "image": Banner1
    },
    {
        "label": "By connecting talented and responsible people",
        "image": Banner2
    },
    {
        "label": "Driven by the Data and Technology",
        "image": Banner3
    }
];

const sliderRef = React.createRef();
const Home = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const countryCode = useSelector(state => state.country);
    const distanceWeight = useSelector(state => state.distanceWeight);

    const [activeStep, setActiveStep] = useState(0);

    const [totalDistanceTravelled, setTotalDistanceTravelled] = useState(0);
    const [totalTonsDelivered, setTotalTonsDelivered] = useState(0);
    const [totalCountriesCovered, setTotalCountriesCovered] = useState(0);

    useEffect(() => {
        checkIfDeviceMobile();

        window.addEventListener('resize', checkIfDeviceMobile, false);
        return () => {
            window.removeEventListener('resize', checkIfDeviceMobile);
        }
    },[]);

    /*
    useEffect(() => {
        
        
        if(Object.entries(distanceWeight).length !== 0){
            // call data on component view
            const distanceAccomplishment = document.querySelector('#h-accomplishments-tile-distance');
            const weightAccomplishment = document.querySelector('#h-accomplishments-tile-weight');
            const countryAccomplishment = document.querySelector('#h-accomplishments-tile-country');
            document.addEventListener('scroll', function () {
                if(Common.isInViewport(distanceAccomplishment)){
                    setTotalDistanceTravelled(distanceWeight.distance);
                }

                if(Common.isInViewport(weightAccomplishment)){
                    setTotalTonsDelivered(distanceWeight.weight);
                }

                if(Common.isInViewport(countryAccomplishment)){
                    setTotalCountriesCovered(6);
                }
            }, {
                passive: true
            });

            dispatch(SHOW_PAGE_LOADER(false));
            checkIfDeviceMobile();
        }
        // eslint-disable-next-line
    },[distanceWeight])
    */

    const checkIfDeviceMobile = () => {
        if(document.querySelector('.h-shipper-image')){
            if(Common.detectMob()){
                document.querySelector('.h-shipper-image').classList.add('mobile-pos');
            }else{
                document.querySelector('.h-shipper-image').classList.remove('mobile-pos');
            }
        }
    }

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        beforeChange: (current, next) => {
            setActiveStep(next);
        }
    };

    const handleNext = () => {
        sliderRef.current.slickNext();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        sliderRef.current.slickPrev();
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
        sliderRef.current.slickGoTo(step);
    };

    const handleRedirectGetAQuote = () => {
        history.push({pathname: `/${countryCode}/get-a-quote`});
    }

    const handleRedirectToServicesPage = (service) => {
        history.push({pathname: `/${countryCode}/services/${service}`});
    }

    const handleRedirectRegistration = (to) => {
        history.push({pathname: `/${countryCode}/sign-up/${to}`});
    }

    return (
        <Box component={'div'}>
            <Box component={'div'}>
                <Slider ref={sliderRef} {...sliderSettings} initialSlide={activeStep}>
                    {
                        images.map((step, index) => (
                            <div key={index}>
                                <div className='h-banner' style={{backgroundImage: `url(${step.image})`}}>
                                    <Typography textAlign="center" variant='h1'>{t(step.label)}</Typography>
                                </div>
                                {/* <LazyLoadImage
                                    alt={step.image.alt}
                                    height={step.image.height}
                                    src={step.image.src} // use normal <img> attributes as props
                                    width={step.image.width} 
                                /> */}
                            </div>
                        ))
                    }
                </Slider>
                <Box component={'div'} className='h-banner-navigation'>
                    <Box component={'div'} className='h-nav-btn-cont'>
                        <Button variant='contained' className='h-nav' onClick={handleBack} disableElevation>
                            <ArrowBack />
                        </Button>
                        <Button variant='contained' className='h-nav' onClick={handleNext} disableElevation>
                            <ArrowForward />
                        </Button>
                    </Box>
                    <Box component={'div'} className='h-nav-pagin-cont'>
                        {
                            images.map((item, key) => (
                                <Button key={key} className={`h-nav-pagin-btn ${activeStep === key && 'active'}`} onClick={() => handleStepChange(key)}></Button>
                            ))
                        }
                    </Box>
                </Box>
            </Box>

            <Fade>
                <Heading 
                    subTitle={t('Our Service')}
                    title={t('We provide end to end solution for your logistic requirements')}
                    dot
                />
            </Fade>
            
            <Fade>
                <Box component={'div'} className='h-services non-surface-pro'>
                    <Box component={'div'}>
                        <div className='h-services-tile' onClick={() => handleRedirectToServicesPage('land-transportation')}>
                            <img src={Services1} alt='' className='h-services-img'/>
                            <Grid container spacing={1} className='justify-center h-services-details'>
                                <Grid item={12}>
                                    <Typography variant='h5'>{t('Land Transportation')}</Typography>
                                </Grid>
                                <Grid item={12}>
                                    <Typography variant='subtitle1' className='h-services-tile-desc'>
                                        {t('Our expertise in land transportation includes Full Truck Load, Less then Truck Load and Inter-island Transportation.')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div className='h-service-tile-bg'/>
                    </Box>
                    <Box component={'div'}>
                        <div className='h-services-tile' onClick={() => handleRedirectToServicesPage('warehouse-storage')}>
                            <img src={Services2} alt='' className='h-services-img'/>
                            <Grid container spacing={1} className='justify-center h-services-details'>
                                <Grid item={12}>
                                    <Typography variant='h5'>{t('Warehouse & Storage')}</Typography>
                                </Grid>
                                <Grid item={12}>
                                    <Typography variant='subtitle1' className='h-services-tile-desc'>
                                        {t('Our wide network leads us to offer warehousing and storage facilities. This includes inventory and can handle distribution with tailor-fit solutions.')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div className='h-service-tile-bg'/>
                    </Box>
                    <Box component={'div'}>
                        <div className='h-services-tile' onClick={() => handleRedirectToServicesPage('air-ocean-freight')}>
                            <img src={Services3} alt='' className='h-services-img'/>
                            <Grid container spacing={1} className='justify-center h-services-details'>
                                <Grid item={12}>
                                    <Typography variant='h5'>{t('Air & Ocean Freight')}</Typography>
                                </Grid>
                                <Grid item={12}>
                                    <Typography variant='subtitle1' className='h-services-tile-desc'>
                                        {t('Customized solutions for your specific Air & Ocean Freight requirement are provided. We are also experienced in handling Customs Clearance.')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div className='h-service-tile-bg'/>
                    </Box>
                    <Box component={'div'}>
                        <div className='h-services-tile' onClick={() => handleRedirectToServicesPage('logistics-optimisation')}>
                            <img src={Services4} alt='' className='h-services-img'/>
                            <Grid container spacing={1} className='justify-center h-services-details'>
                                <Grid item={12}>
                                    <Typography variant='h5'>{t('Logistics Optimisation')}</Typography>
                                </Grid>
                                <Grid item={12}>
                                    <Typography variant='subtitle1' className='h-services-tile-desc'>
                                        {t('Flexible solution that provides Inbound & Outbound Process Analyzation, In-transit Delivery Trip Analyzation that leads to Efficiency & Cost Saving.')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div className='h-service-tile-bg'/>
                    </Box>
                </Box>
            </Fade>

            <Box component={'div'} className='h-get-a-quote'>
                <p onClick={handleRedirectGetAQuote}>
                    {t('Get a Quote for Your Business')}&nbsp;&nbsp;
                    <ArrowForward fontSize='10'/>
                </p>
            </Box>

            <Box component={'div'} className='h-are-you-shipper-trucker'>
                <Fade>
                    <Box component={'div'} className='h-are-you-shipper-trucker-cont'>
                        <img src={AreYouAShipper} alt='' className='h-shipper-image-1'/>
                        <Box component={'div'} className='h-desc-shipper clicker' onClick={() => handleRedirectRegistration('shipper')}></Box>
                        <Box component={'div'} className='h-desc-shipper'>
                            <Typography variant='h3'>{t('Are you a Shipper?')}</Typography>
                            <Typography variant='h5'>{t('Looking for flexible fleet solution and best trucking partner regardless of the market conditions? Register now to know more!')}</Typography>
                        </Box>
                        <Fade left>
                            <img src={AreYouAShipper2} alt='' className='h-shipper-image'/>
                        </Fade>
                    </Box>
                </Fade>
                
                <Fade>
                    <Box component={'div'} className='h-are-you-shipper-trucker-cont'>
                        <img src={AreYouATrucker} alt='' className='h-trucker-image-1' />
                        <Box component={'div'} className='h-desc-trucker clicker' onClick={() => handleRedirectRegistration('carrier')}></Box>
                        <Box component={'div'} className='h-desc-trucker'>
                            <Typography variant='h3'>{t('Are you a Carrier?')}</Typography>
                            <Typography variant='h5'>{t('Daily deliveries, fast payment, with value-added services await you! Register now to maximize your transportation capacity!')}</Typography>
                        </Box>
                        <Fade right>
                            <img src={AreYouATrucker2} alt='' className='h-trucker-image'/>
                        </Fade>
                    </Box>
                </Fade>
            </Box>
            
            <div className='spacer'/>

            <Fade>
                <Heading 
                    subTitle={t('ACCOMPLISHMENTS')}
                    title={t('Let the numbers speak for themselves')}
                    dot
                />
            </Fade>
            
            <Fade>
                <Box component={'div'} className='h-accomplishments'>
                    <Box component={'div'} className='h-accomplishments-tile' id='h-accomplishments-tile-distance'>
                        <img src={Accomplishment1} alt='' width='699' height='605'/>
                        <Box component={'div'} className='h-accomplishment-count'>
                            <Odometer format=",ddd" value='25534120' />
                            <span className='unit'>&nbsp;{t('KM')}</span>
                        </Box>
                        <Heading 
                            title={t('Total distance travelled')}
                            dot
                            variant='h4'
                            className='h-accomplishments-title'
                        />
                        <Typography variant='subtitle'>
                            {t('Our commitment is to deliver your goods anywhere you want. We are bringing the best Logistics Solutions at your fingertips.')}
                        </Typography>
                    </Box>
                    <Box component={'div'} className='h-accomplishments-tile' id='h-accomplishments-tile-weight'>
                        <img src={Accomplishment2} alt='' width='699' height='605'/>
                        <Box component={'div'} className='h-accomplishment-count'>
                            <Odometer format=",ddd" value='1231681' />
                            <span className='unit'>&nbsp;{t('TONS')}</span>
                        </Box>
                        <Heading 
                            title={t('Tons of goods delivered')}
                            dot
                            variant='h4'
                            className='h-accomplishments-title'
                        />
                        <Typography variant='subtitle'>
                            {t('Our services are designed to handle and cater any type of commodities.')}
                        </Typography>
                    </Box>
                    <Box component={'div'} className='h-accomplishments-tile' id='h-accomplishments-tile-country'>
                        <img src={Accomplishment3} alt='' width='699' height='605'/>
                        <Odometer format=",ddd" value='6' />
                        <Heading 
                            title={t('SEA countries covered')}
                            dot
                            variant='h4'
                            className='h-accomplishments-title'
                        />
                        <Typography variant='subtitle'>
                            {t('We plan and implement industry-leading solutions. Firmly supports and executes best fleet services accross the nation.')}
                        </Typography>
                    </Box>
                </Box>
            </Fade>

            <Fade>
                <div>
                    <Heading 
                        subTitle={t('Awards')}
                        title={t('Awards and Recognition')}
                        dot
                    />

                    <Box component={'div'} className='h-awards'>
                        <img src={Award} alt='' />
                    </Box>
                </div>
            </Fade>
            
            <Fade>
                <div>
                    <Heading 
                        subTitle={t('Media')}
                        title={t('Inteluck in the News')}
                        dot
                    />

                    <News />
                </div>
            </Fade>
            
            <Fade>
                <Box component={'div'} className='h-graph'>
                    <img src={Graph} alt='' />
                    <Box component={'div'} className='h-graph-desc'>
                        <Heading 
                            title={t('How we utilize the data to optimize your supply chain')}
                            dot
                        />
                        <Typography variant='subtitle1'>
                            {t('Our big data from our fleet helps streamline and give transparency to the entire supply chain. This is for the benefit of both logistics and shipper companies. We aim to assist out client and partners to pivot into more profitable directions.')}
                        </Typography>
                        <Box component={'div'} className='h-get-a-quote'>
                            <p onClick={handleRedirectGetAQuote}>
                                {t('Get a Quote for Your Business')}&nbsp;&nbsp;
                                <ArrowForward fontSize='10'/>
                            </p>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Box>
    )
}

export default Home;