import React,{ useEffect, useState, Suspense } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import './App.css';
import './assets/scss/main.scss';

import { Backdrop, CircularProgress, Fade } from '@mui/material';

// header
import Header from './components/Header';

// footer
import Footer from './components/Footer';

// pages
import Home from './pages/Home';
import AboutUs from './pages/About-Us';
import Services from './pages/Services';
import Careers from './pages/Careers';
import CareerItem from './pages/Careers/CareerItem';
import ContactUs from './pages/Contact-Us';
import Login from './pages/Login';
import SignUp from './pages/Sign-Up';
import GetAQuote from './pages/Get-a-Quote';
import ThankYou from './pages/Thank-You';
import Information from './pages/Information';

import CookieNotice from './components/CookieNotice';

import { Router, Switch, Route, Redirect } from 'react-router-dom';

import history from './history';

import { SHOW_PAGE_LOADER, SET_DISTANCE_WEIGHT } from './action';

import inteluck from './apis/inteluck';

import moment from 'moment';

import CountryList from './assets/constants/country-list';
import * as Common from '../src/assets/js/common';

import Pages from './assets/constants/constant.json';

import './i18n';
import {useTranslation} from 'react-i18next';

const App = () => {
  const {i18n} = useTranslation();

  const dispatch = useDispatch();
  const countryCode = useSelector(state => state.country);
  const pageLoader = useSelector(state => state.pageLoader);
  const distanceWeight = useSelector(state => state.distanceWeight);

  const [showPage, setShowPage] = useState(false);

  useEffect(() => {
    const countryCode = Common.getCookie('web-country') || 'SG';
    i18n.changeLanguage(countryCode);

    console.info(window.location.pathname);

    // check if the route is existing
    // const currentRoute = window.location.pathname;
    // const mainPage = Pages.menu.find(n => `/${countryCode}${n.url}`.includes(currentRoute))

    // const servicesPage = Pages.menu.find(n => n.menu === 'Services');
    // const subPage = servicesPage['sub-menu'].find(n => `/${countryCode}${n.url}`.includes(currentRoute));

    // const otherPageList = ['/about-us','']

    // if(mainPage === undefined && subPage === undefined){
    //   history.push({pathname: `/${countryCode}/`});
    //   // return
    // }

    // handleGetDashboardCount();

    // if(window.location.pathname === '/'){
    //   history.replace({pathname: `/${countryCode}/`});
    // }

    history.listen(() => {
      window.scrollTo(0,0);
    })
    // eslint-disable-next-line
  },[]);

  /*
  useEffect(() => {
    if(Object.entries(distanceWeight).length !== 0){
      dispatch(SHOW_PAGE_LOADER(false));
    }
    // eslint-disable-next-line
  },[distanceWeight]);
  */

  useEffect(() => {
    dispatch(SHOW_PAGE_LOADER(false));
    setShowPage(true);
    if(!pageLoader){
      const widget_id = '7pUWthtQVW'; 
      const d = document; 
      const w = window; 
      function l() {
          let s = document.createElement('script'); 
          s.type = 'text/javascript'; 
          s.async = true;
          s.src = '//code.jivosite.com/script/widget/' + widget_id ; 
          let ss = document.getElementsByTagName('script')[0]; 
          ss.parentNode.insertBefore(s, ss);
      }
      if (d.readyState === 'complete') { l(); } else {
          if (w.attachEvent) { w.attachEvent('onload', l); }
          else { w.addEventListener('load', l, false); }
      }
    }
  },[pageLoader])

  /*
  const handleGetDashboardCount = () => {
    setTimeout(() => {
      setShowPage(true);
    }, 1000);

    inteluck.get('tmp/Report/Dashboard',{params: {fromDate: '01/01/2015', toDate: moment(new Date()).format('MM/DD/YYYY')}})
    .then(res => {
        if(res.status === 200){
            const data = res.data[0];

            const distance = data.total_distance_weight.find(n => n.description === 'DISTANCE');
            const weight = data.total_distance_weight.find(n => n.description === 'WEIGHT');

            dispatch(SET_DISTANCE_WEIGHT({distance: distance.count, weight: weight.count}));
        }
    })
  }
  */

  return (
    <div>
      <Fade in={pageLoader}>
        <Backdrop className='page-loader'>
          <CircularProgress />
        </Backdrop>
      </Fade>

      {
        showPage && (
          <>
            <Header />

            <Router history={history}>
              {/* <Switch>
                Singapore routes
                <Route exact path='/SG/' component={Home}/>
                <Route exact path='/SG/about-us' render={() => <AboutUs />}/>
                <Route exact path='/SG/services/:service' render={() => <Services />}/>
                <Route exact path='/SG/careers/' render={() => <Careers />}/>
                <Route exact path='/SG/careers/:position' render={() => <CareerItem />}/>
                <Route exact path='/SG/contact-us' render={() => <ContactUs />}/>
                <Route exact path='/SG/login/:type' render={() => <Login />}/>
                <Route exact path='/SG/sign-up/:type' render={() => <SignUp />}/>
                <Route exact path='/SG/get-a-quote' render={() => <GetAQuote />}/>
                <Route exact path='/SG/thank-you' render={() => <ThankYou />} />
                <Route exact path='/SG/information/:document' render={() => <Information />} />

                Philippine routes
                <Route exact path='/PH/' component={Home}/>
                <Route exact path='/PH/about-us' render={() => <AboutUs />}/>
                <Route exact path='/PH/services/:service' render={() => <Services />}/>
                <Route exact path='/PH/careers/' render={() => <Careers />}/>
                <Route exact path='/PH/careers/:position' render={() => <CareerItem />}/>
                <Route exact path='/PH/contact-us' render={() => <ContactUs />}/>
                <Route exact path='/PH/login/:type' render={() => <Login />}/>
                <Route exact path='/PH/sign-up/:type' render={() => <SignUp />}/>
                <Route exact path='/PH/get-a-quote' render={() => <GetAQuote />}/>
                <Route exact path='/PH/thank-you' render={() => <ThankYou />} />
                <Route exact path='/PH/information/:document' render={() => <Information />} />

                Thailand routes
                <Route exact path='/TH/' component={Home}/>
                <Route exact path='/TH/about-us' render={() => <AboutUs />}/>
                <Route exact path='/TH/services/:service' render={() => <Services />}/>
                <Route exact path='/TH/careers/' render={() => <Careers />}/>
                <Route exact path='/TH/careers/:position' render={() => <CareerItem />}/>
                <Route exact path='/TH/contact-us' render={() => <ContactUs />}/>
                <Route exact path='/TH/login/:type' render={() => <Login />}/>
                <Route exact path='/TH/sign-up/:type' render={() => <SignUp />}/>
                <Route exact path='/TH/get-a-quote' render={() => <GetAQuote />}/>
                <Route exact path='/TH/thank-you' render={() => <ThankYou />} />
                <Route exact path='/TH/information/:document' render={() => <Information />} />

                <Route exact path={`/`}>
                  <Redirect to={`/${countryCode}/`} />
                </Route>
              </Switch> */}
              <Suspense 
                fallback={
                  <Backdrop className='page-loader'>
                    <CircularProgress />
                  </Backdrop>
                }
              >
                <Switch>
                  {/* {
                    CountryList.map((item) => {
                      if(item.code === countryCode){
                        return  <>
                                  <Route exact path={`/${item.code}/`} component={Home}/>
                                  <Route exact path={`/${item.code}/about-us`} render={() => <AboutUs />}/>
                                  <Route exact path={`/${item.code}/services/:service`} render={() => <Services />}/>
                                  <Route exact path={`/${item.code}/careers/`} render={() => <Careers />}/>
                                  <Route exact path={`/${item.code}/careers/:position`} render={() => <CareerItem />}/>
                                  <Route exact path={`/${item.code}/contact-us`} render={() => <ContactUs />}/>
                                  <Route exact path={`/${item.code}/login/:type`} render={() => <Login />}/>
                                  <Route exact path={`/${item.code}/sign-up/:type`} render={() => <SignUp />}/>
                                  <Route exact path={`/${item.code}/get-a-quote`} render={() => <GetAQuote />}/>
                                  <Route exact path={`/${item.code}/thank-you`} render={() => <ThankYou />} />
                                  <Route exact path={`/${item.code}/information/:type`} render={() => <Information />} />

                                  <Route exact path={`/`}>
                                    <Redirect to={`/${item.code}/`} />
                                  </Route>
                                </>
                      }
                    })
                  } */}
                  {/* Singapore routes */}
                  <Route exact path='/SG/' component={Home}/>
                  <Route exact path='/SG/about-us' render={() => <AboutUs />}/>
                  <Route exact path='/SG/services/:service' render={() => <Services />}/>
                  <Route exact path='/SG/careers/' render={() => <Careers />}/>
                  <Route exact path='/SG/careers/:position' render={() => <CareerItem />}/>
                  <Route exact path='/SG/contact-us' render={() => <ContactUs />}/>
                  <Route exact path='/SG/login/:type' render={() => <Login />}/>
                  <Route exact path='/SG/sign-up/:type' render={() => <SignUp />}/>
                  <Route exact path='/SG/get-a-quote' render={() => <GetAQuote />}/>
                  <Route exact path='/SG/thank-you' render={() => <ThankYou />} />
                  <Route exact path='/SG/information/:type' render={() => <Information />} />

                  {/* Philippine routes */}
                  <Route exact path='/PH/' component={Home}/>
                  <Route exact path='/PH/about-us' render={() => <AboutUs />}/>
                  <Route exact path='/PH/services/:service' render={() => <Services />}/>
                  <Route exact path='/PH/careers/' render={() => <Careers />}/>
                  <Route exact path='/PH/careers/:position' render={() => <CareerItem />}/>
                  <Route exact path='/PH/contact-us' render={() => <ContactUs />}/>
                  <Route exact path='/PH/login/:type' render={() => <Login />}/>
                  <Route exact path='/PH/sign-up/:type' render={() => <SignUp />}/>
                  <Route exact path='/PH/get-a-quote' render={() => <GetAQuote />}/>
                  <Route exact path='/PH/thank-you' render={() => <ThankYou />} />
                  <Route exact path='/PH/information/:type' render={() => <Information />} />

                  {/* Thailand routes */}
                  <Route exact path='/TH/' component={Home}/>
                  <Route exact path='/TH/about-us' render={() => <AboutUs />}/>
                  <Route exact path='/TH/services/:service' render={() => <Services />}/>
                  <Route exact path='/TH/careers/' render={() => <Careers />}/>
                  <Route exact path='/TH/careers/:position' render={() => <CareerItem />}/>
                  <Route exact path='/TH/contact-us' render={() => <ContactUs />}/>
                  <Route exact path='/TH/login/:type' render={() => <Login />}/>
                  <Route exact path='/TH/sign-up/:type' render={() => <SignUp />}/>
                  <Route exact path='/TH/get-a-quote' render={() => <GetAQuote />}/>
                  <Route exact path='/TH/thank-you' render={() => <ThankYou />} />
                  <Route exact path='/TH/information/:type' render={() => <Information />} />

                  <Route exact path={`/`}>
                    <Redirect to={`/${countryCode}/`} />
                  </Route>
                  <Route exact path={``}>
                    <Redirect to={`/${countryCode}/`} />
                  </Route>
                </Switch>
              </Suspense>
            </Router>

            <CookieNotice />

            <Footer />
          </>
        )
      }
    </div>
  )
}

export default App;
