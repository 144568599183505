import React, { useState, useEffect } from 'react';

// style
import './style.scss';

import { Typography, Box, Card, CardContent, CardMedia } from '@mui/material';

// news images
import News1 from '../../assets/img/img-news-1.jpg';
import News2 from '../../assets/img/img-news-2.jpg';
import News3 from '../../assets/img/img-news-3.jpg';
import News4 from '../../assets/img/img-news-4.jpg';
import News5 from '../../assets/img/img-news-5.png';
import News6 from '../../assets/img/img-news-6.png';
import News7 from '../../assets/img/img-news-7.png';

// for slider
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const newsList = [
    {
        headline: 'Inteluck closes US$34M Series C round to expand regional footprint',
        image: News7,
        link: 'https://e27.co/inteluck-closes-us34m-series-c-round-to-expand-regional-footprint-20231024/',
        origin: 'e27.co'
    },
    {
        headline: 'Inteluck Founder & CEO on the cover of Forbes Asia June 2022',
        image: News6,
        link: 'https://www.forbes.com/sites/jdoebele/2022/06/09/editors-sidelines-june-2022-its-the-economy-stupid/?sh=21eadbf5e9ed',
        origin: 'forbes.com'
    },
    {
        headline: 'Forbes 30, Under 30 Asia honorees in the Industry, Manufacturing & Energy',
        image: News5,
        link: 'https://www.forbes.com/sites/johnkang/2022/05/25/the-30-under-30-asia-2022-entrepreneurs-transforming-the-booming-logistics-industry/?ss=entrepreneurs&sh=2117216d7c67',
        origin: 'forbes.com'
    },
    {
        headline: 'Logistics platform Inteluck closes US$15M Series B round for SEA expansion',
        image: News1,
        link: 'https://e27.co/logistics-platform-inteluck-winds-up-us15m-series-b-round-for-sea-expansion-20220126/',
        origin: 'e27.co'
    },
    {
        headline: 'Lalamove backs logistics startup Inteluck in $5m round led by MindWorks',
        image: News3,
        link: 'https://www.techinasia.com/inteluck-5m-round',
        origin: 'techinasia.com'
    },
    {
        headline: 'SE Asian logistics startup Inteluck bags $15m from Creo Capital, East Ventures',
        image: News2,
        link: 'https://www.dealstreetasia.com/stories/inteluck-creo-east-ventures-279058/',
        origin: 'dealstreetasia.com'
    },
    {
        headline: 'PH logistics startup Inteluck secures fresh funds from China’s FutureCap',
        image: News4,
        link: 'https://www.dealstreetasia.com/stories/ph-logistics-startup-inteluck-secures-1m-from-china-vc-futurecap-128428/',
        origin: 'dealstreetasia.com'
    }
]

const sliderRef = React.createRef();
const News = () => {
    const [sliderSettings, setSliderSettings] = useState({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    useEffect(() => {
        // const browserWidth = getCurrentBrowserWidth();
        // handleUpdateSliderSettings(browserWidth);

        // window.onresize = () => {
        //     const reizedWidth = getCurrentBrowserWidth();
        //     handleUpdateSliderSettings(reizedWidth);
        // }

        // eslint-disable-next-line
    },[]);

    const getCurrentBrowserWidth = () => {
        return parseFloat(window.getComputedStyle(document.getElementsByTagName('body')[0]).getPropertyValue("width").replace('px',''));
    }

    const getCurrentBrowserHeight = () => {
        return parseFloat(window.getComputedStyle(document.getElementsByTagName('body')[0]).getPropertyValue("height").replace('px',''));
    }

    const handleUpdateSliderSettings = (width) => {
        const settings = {...sliderSettings};
        console.info(width);
        switch(width) {
            case 375:
            case 390:
            case 360:
            case 393:
            case 412:
            case 414:
            case 667:
            case 768:
                settings.slidesToShow = 1;
                settings.slidesToScroll = 1;
                break;
            case 740:
            case 761:
            case 807:
            case 820:
            case 844:
            case 851:
            case 896:
            case 912:
            case 914:
            case 915:
            case 1024:
                settings.slidesToShow = 2;
                settings.slidesToScroll = 2;
                break;
            default:
                break;
        }

        // for Ipad Mini
        const IpadMiniHeight = getCurrentBrowserHeight();
        console.info(IpadMiniHeight);
        if(width === 768 && (IpadMiniHeight === 7464.53 || IpadMiniHeight === 7704.53 || IpadMiniHeight === 8264.92 || IpadMiniHeight === 8484.2 || IpadMiniHeight === 8477.2)){
            settings.slidesToShow = 2;
            settings.slidesToScroll = 2;
        }else if (width === 1024 && (IpadMiniHeight === 7163.78 || IpadMiniHeight === 7299.78 || IpadMiniHeight === 7179.78 || IpadMiniHeight === 7468.12 || IpadMiniHeight === 7660.12 || IpadMiniHeight === 7560.3)) {
            settings.slidesToShow = 2;
            settings.slidesToScroll = 2;
        }

        setSliderSettings(settings);
    }

    return (
        <Box component={'div'} className='news'>
            <Slider ref={sliderRef} {...sliderSettings}>
                {
                    newsList.map((item, key) => (
                        <div key={key}>
                            <Card key={key}>
                                <CardMedia
                                    component="img"
                                    height="250"
                                    image={item.image}
                                    alt=""
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h4" component="div">
                                        {item.headline}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {item.origin}
                                    </Typography>
                                </CardContent>
                                <a href={item.link} target='_blank'>L</a>
                            </Card>
                        </div>
                    ))
                }
            </Slider>
        </Box>
    )
}

export default News;