const resources = {
    TH: {
        translation: {
            "Home" : "หน้าแรก",
            "About Us" : "เกี่ยวกับเรา",
            "Services" : "บริการ",
            "Careers" : "อาชีพ",
            "Contact Us" : "การติดต่อ",
            "I'm a Shipper" : "เราคือเจ้าของสินค้า",
            "I'm a Carrier" : "เราคืือผู้ขนส่ง",
            "Login" : "เข้าสู่ระบบ",
            "Sign Up" : "ลงชื่อ",
            "Land Transportation" : "การขนส่งทางบก",
            "Air & Ocean Freight" : "การขนส่งทางอากาศและทางเรือ",
            "Warehouse & Storage" : "คลังสินค้าและการจัดเก็บสินค้า",
            "Logistics Optimisation" : "การเพิ่มประสิทธิภาพโลจิสติกส์",
            "Build the most efficient logistics world" : "การสร้างโลกโลจิสติกส์ที่มีประสิทธิภาพสูงสุด",
            "By connecting talented and responsible people" : "โดยเชื่อมโยงผู้มีความสามารถและมีความรับผิดชอบ",
            "Driven by the Data and Technology" : "ขับเคลื่อนโดยข้อมูลและเทคโนโลยี",
            "Our Service" : "บริการของเรา",
            "We provide end to end solution for your logistic requirements" : "เรามีโซลูชันแบบครบวงจรสำหรับความต้องการ ด้านโลจิสติกส์ของคุณ",
            "Our expertise in land transportation includes Full Truck Load, Less then Truck Load and Inter-island Transportation." : "เรามีความเชี่ยวชาญของเราในการขนส่งทางบก ได้แก่ การบรรทุกเต็มรถบรรทุกน้อยกว่าการบรรทุกและการขนส่งระหว่างเกาะ (การจัดส่งแบบถึงประตูบ้านท่าน)",
            "Our wide network leads us to offer warehousing and storage facilities. This includes inventory and can handle distribution with tailor-fit solutions." : "เราสามารถให้บริการคลังสินค้าและการจัดเก็บและสามารถจัดหาโซลูชันที่กำหนดเองเพื่อแจกจ่ายสถานที่ต่างๆ",
            "Customized solutions for your specific Air & Ocean Freight requirement are provided. We are also experienced in handling Customs Clearance." : "เรามีการจัดการพิเศษเฉพาะสำหรับความต้องการขนส่งทางอากาศและทางทะเล เรายังมีการประสานงานสำหรับศุลกากรอีกด้วย",
            "Flexible solution that provides Inbound & Outbound Process Analyzation, In-transit Delivery Trip Analyzation that leads to Efficiency & Cost Saving." : "การแก้ปัญหาอย่างยืดหยุ่นซึ่งให้การวิเคราะห์กระบวนการขาเข้าและขาออก การวิเคราะห์การเดินทางระหว่างการขนส่ง จึงช่วยเพิ่มประสิทธิภาพและลดต้นทุน",
            "Get a Quote for Your Business" : "รับใบเสนอราคาสำหรับธุรกิจของคุณ",
            "Are you a Shipper?" : "คุณเป็นเจ้าของสินค้าหรือไม่?",
            "Looking for flexible fleet solution and best trucking partner regardless of the market conditions? Register now to know more!" : "กำลังมองหาโซลูชันยานพาหนะที่ยืดหยุ่นและพันธมิตรด้านการขนส่งที่ดีที่สุดโดยไม่คำนึงถึงสภาวะตลาดหรือไม่? ลงทะเบียนตอนนี้เพื่อรุ็ข้อมูลเพิ่มเติม!",
            "Are you a Carrier?" : "คุณเป็นผู้ขนส่งหรือไม่?",
            "Daily deliveries, fast payment, with value-added services await you! Register now to maximize your transportation capacity!" : "จัดส่งทุกวันชำระเงินรวดเร็วพร้อมบริการเสริมรอคุณอยู่! ลงทะเบียนตอนนี้เพื่อเพิ่มขีดความสามารถในการขนส่งของคุณ!",
            "ACCOMPLISHMENTS" : "ความสำเร็จ",
            "Let the numbers speak for themselves" : "กำหนดตัวเลขด้วยตัวคุณเอง",
            "KM" : "กม",
            "TONS" : "ตัน",
            "Total distance travelled" : "ระยะทางขนส่งทั้งหมด",
            "Our commitment is to deliver your goods anywhere you want. We are bringing the best Logistics Solutions at your fingertips." : "เราสัญญาว่าจะจัดส่งสินค้าของคุณไปทุกที่ที่คุณต้องการ เพราะเราคือผู้ให้บริการด้านโลจิสติกส์ที่ดีที่สุดแก่คุณ",
            "Tons of goods delivered" : "จำนวนตันของสินของที่ส่ง",
            "Our services are designed to handle and cater any type of commodities." : "หลักการบริการของเราคือเพื่อตอบสนองการกระจายสินค้าทุกประเภท",
            "SEA countries covered" : "บริการครอบคลุมประเทศในแถบทะเล",
            "We plan and implement industry-leading solutions. Firmly supports and executes best fleet services accross the nation." : "เรามีแผนที่จะเป็นชั้นนำของอุตสาหกรรม สนับสนุนและดำเนินการบริการด้านการขนส่งที่ดีที่สุดทั่วประเทศอย่างมั่นคง",
            "Media" : "สื่อ",
            "Inteluck in the News" : "อินเทลนัคในข่าว",
            "Awards" : "รางวัล",
            "Awards and Recognition" : "รางวัลและการยอมรับ",
            "How we utilize the data to optimize your supply chain" : "เราใช้ข้อมูลเพื่อเพิ่มประสิทธิภาพในการทำงานร่วมกันในการส่งสินค้า/การกระจายสินค้าอย่างไร",
            "Our big data from our fleet helps streamline and give transparency to the entire supply chain. This is for the benefit of both logistics and shipper companies. We aim to assist out client and partners to pivot into more profitable directions." : "ข้อมูลขนาดใหญ่ของเราจากกองเรือของเราช่วยเพิ่มความคล่องตัวและให้ความโปร่งใสแก่การส่งสินค้า/การกระจายสินค้า ทั้งนี้เพื่อประโยชน์ของทั้ง บริษัท ขนส่งและ เรามุ่งมั่นที่จะช่วยเหลือลูกค้าและคู่ค้าของเราให้เปลี่ยนไปสู่ทิศทางที่ทำกำไรได้มากขึ้น",
            "Company" : "บริิษัท",
            "Information" : "ข้อมูล",
            "Data Privacy Policy" : "นโยบายความเป็นส่วนตัวของข้อมูล",
            "Terms & Conditions" : "ข้อตกลงและเงื่อนไข",
            "Useful links" : "ลิงค์ที่มีประโยชน์",
            "Register Shipper" : "ลงทะเบียนเจ้าของสินค้า",
            "Register Carrier" : "ลงทะเบียนผู้ขนส่ง",
            "Get a quote" : "ขอใบเสนอราคา",
            "Efficiency, security and consistency are the keys to have a successful land transportation services, and that’s what we pride ourselves. Inteluck’s services create added value in the following areas." : "ประสิทธิภาพ ความปลอดภัยและความสม่ำเสมอเป็นหัวใจสำคัญในการให้บริการขนส่งทางบกซึ่งเป็นเหตุผลที่เราภาคภูมิใจ บริการของ Inteluck สร้างมูลค่าเพิ่มในด้านต่อไปนี้",
            "Our dedicated solutions offer complete and extensive process from pickup to drop off point. Transportation via air and sea can be applied to complete the distribution operations." : "โซลูชันของเรามีกระบวนการทั้งหมดตั้งแต่การรับของไปจนถึงจุดขนถ่าย การดำเนินการจัดส่งสามารถทำได้ทั้งทางอากาศและทางทะเล",
            "Our wide network leads us to offer warehousing and storage facilities to our customers. This service also includes inventory management and can also handle your distribution with tailor-fit solutions." : "เครือข่ายที่กว้างขวางของเราทำให้เรานำเสนอคลังสินค้าและสิ่งอำนวยความสะดวกในการจัดเก็บ ซึ่งรวมถึงสินค้าคงคลังและสามารถจัดการการกระจายสินค้าอย่างลงตัว",
            "We provide data-driven logistics platform services to enterprises across the Southeast Asia. Delivering desired results for our clients are our expertise." : "เราให้บริการแพลตฟอร์มโลจิสติกส์ที่ขับเคลื่อนด้วยข้อมูลสำหรับ บริษัท ในเอเชียตะวันออกเฉียงใต้ ความเชี่ยวชาญของเราคือการให้ผลลัพธ์ที่ดีเยี่ยมแก่ลูกค้า",
            "Our Services" : "บริการของเรา",
            "Land Transportation Services" : "บริการขนส่งทางบก",
            "Our expertise in land transportation includes Full Truck Load, Less than Truck Load and Inter-island Transportation." : "ความเชี่ยวชาญของเราคือการขนส่งทางบกรวมถึงยานพาหนะธุรกิจ LTL และการขนส่งระหว่างเกาะ (การจัดส่งแบบถึงบ้านท่าน)",
            "Full Truck Load" : "การขนส่งแบบบรรทุกเต็ม(FTL)",
            "Any truck type is available, from closed van, open truck, up to tractor head. Your shipments with large quantity will be carried out in a dedicated truck for delivery. FTL shipments get to the destination faster and less handling; safety and security are also being observed and well-implemented. We can process the delivery and truck dispatch at your earliest convenience." : "เราสามารถเสนอรถบรรทุกได้ทุกประเภท ตั้งแต่รถบรรทุกปิดรถบรรทุกแบบเปิดไปจนถึงรถพ่วง การจัดส่งสินค้าจำนวนมากของคุณจะจัดส่งโดยใช้รถบรรทุกพิเศษ ความเร็วในการจัดส่งของรถทั้งคันไปยังปลายทางเร็วขึ้นการขนถ่ายลดลงและสินค้าปลอดภัย และเราสามารถจัดส่งโดยเร็วที่สุดตามเวลาความสะดวกของคุณ",
            "Less than Truck Load" : "การขนส่งแบบบรรทุกไม่เต็ม(LTL)",
            "Aside from bulk, we are also catering less than truck load (LTL) deliveries. LTL is costeffective and very ideal for small businesses or small shipment quantity. LTL only pay the cost for the portion the shipment consumed or occupied. Our big data will be responsible in analyzing the route and possible shippers for sharing. Easier and faster transactions are now accessible!" : "นอกจากการขนส่งสินค้าจำนวนมากแล้วเรายังมีบริการจัดส่งแบบ LTL ซึ่งคุ้มค่าและเหมาะสำหรับธุรกิจขนาดเล็กหรือการขนส่งขนาดเล็ก LTLจะต้องจ่ายเฉพาะส่วนที่ใช้หรือส่วนที่ครอบครองของการจัดส่งเท่านั้น เราจะใช้ Big Data มารับผิดชอบในการวิเคราะห์เส้นทางและแบ่งปันกับผู้ขนส่งที่อาจส่งมอบ สามารถซื้อขายได้ง่ายและเร็วขึ้น!",
            "Inter-island Transportation" : "การขนส่งระหว่างเกาะ",
            "Our wide network leads the solution for inter-island transport. Farther locations can now be reached through this kind of service. Proper handling and documentation will also being cared for as needed. Nationwide delivery can be outstretched as we are offering door to door delivery services." : "เรามีเครือข่ายที่กว้างขวางเพื่อให้บริการโซลูชั่นการขนส่งระหว่างเกาะ ขณะนี้สามารถเข้าถึงสถานที่ที่อยู่ไกลออกไปได้ผ่านบริการนี้ การจัดการเอกสารและอื่นๆที่เหมาะสมจะได้รับการจัดการตามความต้องการ เนื่องจากเราให้บริการจัดส่งแบบ door-to-door ขอบเขตของการจัดส่งทั่วประเทศอาจขยายออกไปเช่นกัน",
            "Domestic Air & Ocean Freight" : "การขนส่งทางอากาศและทางเรือภายในประเทศ",
            "We provide a custom-made logistics services solution for our clients. Goods or deliveries can be transported via air or sea, depending on your requirement. Import/Export need are well executed by our Logistics Team. Our nature is to give an end to end solution just for you!" : "เราจะจัดหาโซลูชันด้านโลจิสติกส์แบบกำหนดเอง ตามความต้องการของคุณคุณสามารถเลือกขนส่งหรือจัดส่งทางอากาศหรือทางทะเล ทีมโลจิสติกส์ของเราจะดำเนินการตามข้อกำหนดการนำเข้าและส่งออกอย่างดี เป้าหมายของเราคือการจัดหาโซลูชันแบบ end-to-end ให้คุณ!",
            "International Air & Ocean Freight" : "การขนส่งทางอากาศและทางทะเลระหว่างประเทศ",
            "Our services’ flexibility leads us in being complete, accurate, and timely with documentation which is a crucial part for international transport. This ensures well-timed delivery for international air and ocean freight deliveries. Import/Export needs are also well executed by our Logistics Team. Our nature is to give an end to end solution just for you!" : "บริการที่ยืดหยุ่นของเราช่วยให้เราสามารถจัดเตรียมเอกสารที่ครบถ้วน ถูกต้องและทันเวลา ซึ่งจำเป็นสำหรับการขนส่งระหว่างประเทศ สิ่งนี้ช่วยให้มั่นใจได้ถึงการขนส่งทางอากาศและทางทะเลระหว่างประเทศ ทีมโลจิสติกส์เรายังสามารถดำเนินการตามข้อกำหนดการนำเข้าและส่งออกได้ดี เป้าหมายของเราคือการจัดหาโซลูชันแบบ end-to-end ให้คุณ!",
            "You will be freed by the simultaneous tasks if you still handle your own storage needs and supply chain duties. We provide a full-featured warehousing and storage for smooth transaction and movement of your cargoes." : "หากคุณต้องการจัดการพื้นที่จัดเก็บและซัพพลายเชนด้วยตัวเอง คุณสามารถปล่อยให้เราได้ เราให้บริการคลังสินค้าและการจัดเก็บที่ทำงานได้อย่างสมบูรณ์เพื่อให้แน่ใจว่าธุรกรรมและการเคลื่อนย้ายสินค้าของคุณเป็นไปอย่างราบรื่น",
            "Warehousing & Cargo Storage" : "คลังสินค้าและการจัดเก็บสินค้า",
            "Our available facilities will support you to have a greater speed, productive transportation and supply chain. We proud ourselves in providing quality services for proper handling and secure storage of your cargoes." : "เราจะจัดหาห่วงโซ่อุปทานการขนส่งที่รวดเร็วและมีประสิทธิภาพ เราภูมิใจที่จะให้บริการที่มีคุณภาพสำหรับการจัดการที่ถูกต้องและการจัดเก็บสินค้าอย่างปลอดภัย",
            "Inbound & Outbound Process Analyzation" : "การวิเคราะห์กระบวนการเข้าคลังและออกคลัง",
            "We proud ourselves in providing solutions for first and mid-mile logistics companies.We are expert with the logistics process, may it be from inbound or outbound. This is with the help and utilization of the data and our well-experienced team that benefits our clients and partners." : "เราภูมิใจในการนำเสนอโซลูชั่นสำหรับ บริษัทโลจิสติกส์รายแรกและรายย่อยเรามีความเชี่ยวชาญในกระบวนการโลจิสติกส์ไม่ว่าจะเป็นจากขาเข้าหรือขาออก นี่คือความช่วยเหลือและการใช้ข้อมูลและทีมงานที่มีประสบการณ์ซึ่งเป็นประโยชน์ต่อลูกค้าและคู่ค้าของเรา",
            "In-Transit Delivery Trip Analyzation" : "การวิเคราะห์การขนส่งระหว่างทาง",
            "All the trucks we used are equipped with our advanced Fleet Management Solution. Real-time tracking, driver behavior and location history reports are readily available from our portal. Our clients have the capability to track their delivery and feel secure with every transaction from us." : "รถบรรทุกทั้งหมดที่เราใช้มาพร้อมกับโซลูชั่นการจัดการยานพาหนะขั้นสูง ติดตามแบบเรียลไทม์พฤติกรรมคนขับและรายงานประวัติตำแหน่งได้จากพอร์ทัลของเรา ลูกค้าของเราสามารถติดตามการจัดส่งและรู้สึกปลอดภัยในทุกธุรกรรมที่เราทำ",
            "Efficiency Improvement & Cost Saving" : "ปรับปรุงประสิทธิภาพและประหยัดค่าใช้จ่าย",
            "Our client and partners can also have an access with our Transportation Management Platform (TMP). With big data on hand, we will help you analyze the available information for the betterment of your logistics process and business. We are your partner in achieving your goals!" : "ลูกค้าและคู่ค้าของเรายังสามารถใช้แพลตฟอร์มการจัดการการขนส่ง (TMP) ของเราได้ ด้วย Big Data เราจะช่วยคุณวิเคราะห์ข้อมูลที่มีอยู่เพื่อปรับปรุงกระบวนการโลจิสติกส์และธุรกิจของคุณ เราเป็นพันธมิตรของคุณเพื่อให้บรรลุเป้าหมายของคุณ",
            "Here to Help Your Business Needs" : "ที่นี่เพื่อช่วยให้ธุรกิจของคุณต้องการ",
            "Stop worrying about your delivery problems. Focus on your business. Let us provide the support you deserve." : "ไม่ต้องกังวลเกี่ยวกับปัญหาในการจัดส่ง เราจะสนับสนุนด้านขนส่งทั้งหมด คุณมุ่งเน้นไปที่ธุรกิจของคุณได้เลย",
            "Stop worrying about any shipping problems. Focus on your business. Let us provide the support you deserve." : "ไม่ต้องกังวลเกี่ยวกับปัญหาในการจัดส่ง เราจะสนับสนุนด้านขนส่งทั้งหมด คุณมุ่งเน้นไปที่ธุรกิจของคุณได้เลย",
            "Get a Quote" : "ขอใบเสนอราคา",
            "Join us now" : "เข้าร่วมกับเราตอนนี้",
            "Join us now." : "เข้าร่วมกับเราตอนนี้",
            "Corporate Event" : "งานองค์กร",
            "Team Activity" : "กิจกรรมของทีม",
            "Social Responsibility" : "ความรับผิดชอบต่อสังคม",
            "Are you ready to make your move?" : "คุณพร้อมที่จะเคลื่อนไหวหรือยัง?",
            "Help Center" : "ศูนย์บริการ",
            "We take care about transportation for your business" : "เราดูแลเกี่ยวกับการขนส่งสำหรับธุรกิจของคุณ",
            "We would be glad to assist you further with your queries, Please drop us a message!" : "เรายินดีที่จะช่วยเหลือคุณเพิ่มเติมเกี่ยวกับข้อสงสัยของคุณโปรดส่งข้อความถึงเรา!",
            "Need to Make an Inquiry?" : "ต้องการสอบถามรายละเอียดเพิ่มเติม?",
            "First Name" : "ชื่อ",
            "Last Name" : "นามสกุล",
            "Mobile/Phone Number" : "เบอร์โทรศัพท์",
            "Email" : "อีเมล์",
            "Message" : "ข่าวสาร",
            "Send" : "ส่ง",
            "Get in Touch With Us" : "ติดต่อกับเรา",
            "Shipper Login" : "เข้าสู่ระบบเจ้าของสินค้า",
            "Carrier Login" : "เข้าสู่ระบบผู้ขนส่ง",
            "Email Address or Username" : "อีเมล์หรือชื่อผู้ใช้",
            "Password" : "รหัสผ่าน",
            "Remember Me" : "จำรหัสผ่าน",
            "Submit" : "ส่ง"
        }
    }
}

export default resources;