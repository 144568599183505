import React from 'react';

import { useSelector } from 'react-redux';

import './style.scss';

import { Card, CardMedia, CardContent, Typography, Chip } from '@mui/material';

import { AccountCircle } from '@mui/icons-material';

import history from '../../history';

const CareerCardItem = ({item}) => {
    const countryCode = useSelector(state => state.country);

    const handleRedirectCareerPosition = (e, title) => {
        e.preventDefault();

        history.push({pathname: `/${countryCode}/careers/${encodeURIComponent(title.toLowerCase().replace(/\s/g,'-'))}`});
    }

    return (
        <Card className='career-card-item'>
            <CardMedia
                component="img"
                height="140"
                image={item.image}
            />
            <CardContent>
                <a href={`/careers/${encodeURIComponent(item.title.toLowerCase().replace(/\s/g,'-'))}`} onClick={(e) => handleRedirectCareerPosition(e, item.title)}>
                    {item.title}
                </a>
                <Typography variant="body2" color="text.secondary">
                    {item.description}
                </Typography>
                <Chip icon={<AccountCircle className='cc-icon' fontSize='large'/>} label='by hr@inteluck.com' className='cc-chip'/>
            </CardContent>
        </Card>
    )
}

export default CareerCardItem;