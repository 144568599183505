import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import './style.scss';

import { Box, Typography, Grid, TextField, Button, FormControlLabel, Checkbox, Select, MenuItem, InputAdornment, Tooltip, Collapse } from '@mui/material';

import { Close, Check } from '@mui/icons-material';

import Fade from 'react-reveal/Fade';

import Heading from '../../components/Heading';

import history from '../../history';

import CountryList from '../../assets/constants/country-list';

import Constant from '../../assets/constants/constant.json';

// description images
import ShipperImage1 from '../../assets/img/signup-shipper-1.png';
import ShipperImage2 from '../../assets/img/signup-shipper-2.png';
import ShipperImage3 from '../../assets/img/signup-shipper-3.png';
import ShipperImage4 from '../../assets/img/signup-shipper-4.png';
import ShipperImage5 from '../../assets/img/signup-shipper-5.png';
import ShipperImage6 from '../../assets/img/signup-shipper-6.png';

import CarrierImage1 from '../../assets/img/signup-carrier-1.png';
import CarrierImage2 from '../../assets/img/signup-carrier-2.png';
import CarrierImage3 from '../../assets/img/signup-carrier-3.png';
import inteluck from '../../apis/inteluck';

import {useTranslation} from 'react-i18next';

const SignUp = () => {
    const {t} = useTranslation();

    const {type} = useParams();

    const ErrorList = Constant.error;
    const countryCode = useSelector(state => state.country);

    const [signUpType, setSignUpType] = useState(type);
    const [registrationDetails, setRegistrationDetails] = useState({});
    const [showError, setShowError] = useState(false);
    const [acceptTermsConditions, setAcceptTermsConditions] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(null);
    const [openToast, setOpenToast] = useState({show: false, message: '', severity: ''});
    const [disableSubmitButton, setDisableSubmitButton] = useState(true);
    const [disableFields, setDisableFields] = useState(false);

    useEffect(() => {
        history.listen((e) => {
            const url = e.pathname.split('/');
            setSignUpType(url[url.length - 1]);
        })
    },[]);

    useEffect(() => {
        if(typeof registrationDetails.password === 'string' && registrationDetails.password !== '' && typeof registrationDetails.confirm_password === 'string' && registrationDetails.confirm_password !== ''){
            setPasswordMatch(registrationDetails.password === registrationDetails.confirm_password);
        }else{
            setPasswordMatch(null);
        }
    },[registrationDetails]);

    useEffect(() => {
        if(openToast.show && openToast.severity !== 'info'){
            setTimeout(() => {
                setOpenToast({...openToast, 'show': false});
            }, 3000);
        }
    },[openToast]);

    useEffect(() => {
        setDisableSubmitButton(!acceptTermsConditions);
    },[acceptTermsConditions])

    const handleSubmitRegistrationOnClick = () => {
        const dataObj = {
            name: registrationDetails.name,
            description: "from website signup form...",
            type: signUpType === 'carrier' ? 'TRUCKER' : signUpType.toUpperCase(),
            status: 'POTENTIAL',
            address: {
              location: "N/A",
              latitude: "",
              longitude: ""
            },
            users_details: [{
              first_name: registrationDetails.first_name,
              middle_name: "",
              last_name: registrationDetails.last_name,
              email_address: registrationDetails.email_address,
              mobile_number: registrationDetails.mobile_number,
              username: registrationDetails.email_address,
              password: registrationDetails.password,
              role: "Owner"
            }],
            primarily_ships: registrationDetails.primarily_ships
        }

        // check if fields are filled up
        const fieldsCheck = [dataObj.primarily_ships, dataObj.name, dataObj.users_details[0].first_name, dataObj.users_details[0].last_name, dataObj.users_details[0].mobile_number, dataObj.users_details[0].email_address, dataObj.users_details[0].password, registrationDetails.confirm_password];
        let isComplete = true;
        fieldsCheck.forEach(item => {
            if(typeof item !== 'string' || item === ''){
                isComplete = false;
            }
        })

        if(passwordMatch === false && isComplete){
            isComplete = false;
            setOpenToast({show: true, message: "Password does not match", severity: 'error'});
            return
        }

        setShowError(true);

        if(isComplete){
            setDisableSubmitButton(true);
            setDisableFields(true);
            setOpenToast({show: true, message: 'Please wait...', severity: 'info'});

            inteluck.post('Clients',dataObj,{
                headers: {
                    "Authorization": "hz9u97NbqLXGsPWc"
                }
            })
            .then(res => {
                if(res.status === 201){
                    setOpenToast({show: true, message: 'Registration Success', severity: 'success'});
                    
                    setTimeout(() => {
                        history.push({pathname: `/${countryCode}/thank-you`});
                    }, 2000);
                }
            })
            .catch(err => {
                if(err.response){
                    const xhr = err.response;
                    const message = xhr.status !== 500 ? xhr.data.message : ErrorList[xhr.status];
                    setOpenToast({show: true, message: message, severity: 'error'});
                    setDisableSubmitButton(false);
                    setDisableFields(false);
                }
            })
        }else{
            setOpenToast({show: true, message: 'Fill up all the fields', severity: 'error'});
        }
    }

    const handleFillRegistrationDetails = (e) => {
        const {name, value} = e.target;
        if(value !== ''){
            setRegistrationDetails({...registrationDetails, [name]: value});
        }else{
            const obj = {...registrationDetails};
            delete obj[name];
            setRegistrationDetails(obj);
        }
    }

    const handleOpenTermsAndConditions = () => {
        window.open(`/${countryCode}/information/terms-%26-conditions`);
    }

    return (
        <Box component={'div'} className='sign-up'>
            <Box component={'div'} className={`s-banner ${signUpType}`}>
                <Box component={'div'} className='sign-up-form-cont'>
                    <Box component={'div'} className='sign-up-cont'>
                        <Heading subTitle={'WE ARE INTELUCK'} title={'Sign Up Now!'} />
                        <Typography variant='subtitle1' className='s-subtitle'>
                            {
                                signUpType === 'shipper' ? (
                                   `Looking for reliable trucks to cater your delivery?
                                   We made it easier for you.`
                                ) : (
                                    `Get additional trips, Schedule your backloads
                                    and Enjoy fast payment!`
                                )
                            }
                        </Typography>
                    </Box>
                    <Box component={'div'} className='sign-up-cont'>
                        <Heading title={`${signUpType[0].toUpperCase()}${signUpType.substring(1)} Registration`} variant='h4' />
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Select
                                    displayEmpty 
                                    className={`select-elem ${(typeof registrationDetails.primarily_ships !== 'string' || registrationDetails.primarily_ships === '') && showError ? 'error' : ''}`}
                                    value={registrationDetails.primarily_ships}
                                    onChange={(e) => setRegistrationDetails({...registrationDetails, 'primarily_ships': e.target.value})}
                                    renderValue={(selected) => {
                                        if(typeof selected !== 'string' || selected === ''){
                                            return <div className='select-placeholder'>Primarily Ships</div>
                                        }else{
                                            return selected
                                        }
                                    }}
                                    disabled={disableFields}
                                    onOpen={() => {
                                        document.querySelector('html').style.overflow = 'hidden';
                                    }}
                                    onClose={() => {
                                        document.querySelector('html').style.overflow = 'unset';
                                    }}
                                >
                                    {
                                        CountryList.map((item, key) => (
                                            item.code !== 'CH' && (
                                                <MenuItem key={key} value={item.name}>{item.name}</MenuItem>
                                            )
                                        ))
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name='name'
                                    value={registrationDetails.name}
                                    onChange={handleFillRegistrationDetails} 
                                    autoComplete='off' 
                                    placeholder='Company'
                                    className={(typeof registrationDetails.name !== 'string' || registrationDetails.name === '') && showError ? 'error' : ''}
                                    disabled={disableFields}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name='first_name'
                                    value={registrationDetails.first_name}
                                    onChange={handleFillRegistrationDetails}  
                                    autoComplete='off' 
                                    placeholder='First Name'
                                    className={(typeof registrationDetails.first_name !== 'string' || registrationDetails.first_name === '') && showError ? 'error' : ''}
                                    disabled={disableFields}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name='last_name'
                                    value={registrationDetails.last_name}
                                    onChange={handleFillRegistrationDetails}  
                                    autoComplete='off' 
                                    placeholder='Last Name'
                                    className={(typeof registrationDetails.last_name !== 'string' || registrationDetails.last_name === '') && showError ? 'error' : ''}
                                    disabled={disableFields}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name='mobile_number'
                                    value={registrationDetails.mobile_number}
                                    onChange={handleFillRegistrationDetails}  
                                    autoComplete='off' 
                                    placeholder='Contact Number' 
                                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                    className={(typeof registrationDetails.mobile_number !== 'string' || registrationDetails.mobile_number === '') && showError ? 'error' : ''}
                                    disabled={disableFields}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name='email_address'
                                    value={registrationDetails.email_address}
                                    onChange={handleFillRegistrationDetails}  
                                    autoComplete='off' 
                                    placeholder='Email'
                                    className={(typeof registrationDetails.email_address !== 'string' || registrationDetails.email_address === '') && showError ? 'error' : ''}
                                    disabled={disableFields} 
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name='password'
                                    value={registrationDetails.password}
                                    onChange={handleFillRegistrationDetails}  
                                    autoComplete='off' 
                                    placeholder='Password' 
                                    type='password'
                                    className={(typeof registrationDetails.password !== 'string' || registrationDetails.password === '') && showError ? 'error' : ''}
                                    InputProps={{
                                        endAdornment: <InputAdornment>
                                            {
                                                passwordMatch === false && (
                                                    <Tooltip title="Passwords don't match" arrow>
                                                        <Close fontSize='small' className='s-password-error'/>
                                                    </Tooltip>
                                                )
                                            }
                                            {
                                                passwordMatch && (
                                                    <Check fontSize='small' className='s-password-success'/>
                                                )
                                            }
                                        </InputAdornment>
                                    }}
                                    disabled={disableFields}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name='confirm_password'
                                    value={registrationDetails.confirm_password}
                                    onChange={handleFillRegistrationDetails}  
                                    autoComplete='off' 
                                    placeholder='Confirm Password' 
                                    type='password'
                                    className={(typeof registrationDetails.confirm_password !== 'string' || registrationDetails.confirm_password === '') && showError ? 'error' : ''}
                                    InputProps={{
                                        endAdornment: <InputAdornment>
                                            {
                                                passwordMatch === false && (
                                                    <Tooltip title="Passwords don't match" arrow>
                                                        <Close fontSize='small' className='s-password-error'/>
                                                    </Tooltip>
                                                )
                                            }
                                            {
                                                passwordMatch && (
                                                    <Check fontSize='small' className='s-password-success'/>
                                                )
                                            }
                                        </InputAdornment>
                                    }}
                                    disabled={disableFields}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box component={'div'} className='s-sign-up-checkbox-cont'>
                                    <Checkbox 
                                        className={signUpType} 
                                        checked={acceptTermsConditions} 
                                        onChange={() => setAcceptTermsConditions(!acceptTermsConditions)} 
                                        disabled={disableFields}
                                    />
                                    <label className='s-label-checkbox'>
                                        I accept the <span className={`s-label-terms ${signUpType}`} onClick={handleOpenTermsAndConditions}>Terms and Conditions</span>.
                                    </label>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Button 
                                    variant='contained' 
                                    className={`button ${signUpType === 'shipper' ? 'green' : 'orange'}`} 
                                    disableElevation 
                                    onClick={handleSubmitRegistrationOnClick}
                                    disabled={disableSubmitButton}
                                >
                                    Submit
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={openToast.show}>
                                    <Box component={'div'} className={`toast ${openToast.severity}`}>
                                        {openToast.message}
                                    </Box>
                                </Collapse>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {/* <Box component={'div'} className='s-banner-overlay' /> */}
            </Box>

            <Box component={'div'} className={`s-desc-list ${signUpType}`}>
                {
                    signUpType === 'shipper' ? (
                        <>
                            <Fade>
                                <Box component={'div'} className='s-desc-list-item'>
                                    <Box component={'div'} className='s-desc-list-item-img'>
                                        <img src={ShipperImage1} alt='' />
                                    </Box>
                                    <Box component={'div'} className='s-desc-cont'>
                                        <Heading title={'Flexible Booking and Dispatch'} dot />
                                        <Typography variant='subtitle1'>
                                            Accepts booking 24/7, with fast confirmation and dispatch! It will only take 5 minutes (min.) to do the online request up to dispatch.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Fade>
                            <Fade>
                                <Box component={'div'} className='s-desc-list-item'>
                                    <Box component={'div'} className='s-desc-cont'>
                                        <Heading title={'Reliable insured Truckers'} dot />
                                        <Typography variant='subtitle1'>
                                            Our trucking partners had passed strict accreditation process prior deployment; involves submission of complete government documents and 100% insured.
                                        </Typography>
                                    </Box>
                                    <Box component={'div'} className='s-desc-list-item-img'>
                                        <img src={ShipperImage2} alt='' />
                                    </Box>
                                </Box>
                            </Fade>
                            <Fade>
                                <Box component={'div'} className='s-desc-list-item'>
                                    <Box component={'div'} className='s-desc-list-item-img'>
                                        <img src={ShipperImage3} alt='' />
                                    </Box>
                                    <Box component={'div'} className='s-desc-cont'>
                                        <Heading title={'Competitive Pricing'} dot />
                                        <Typography variant='subtitle1'>
                                            Our Transportation Artificial Platform and data utilization provide customers’ competitive and reasonable logistics pricing.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Fade>
                            <Fade>
                                <Box component={'div'} className='s-desc-list-item'>
                                    <Box component={'div'} className='s-desc-cont'>
                                        <Heading title={'Various Fleet Available'} dot />
                                        <Typography variant='subtitle1'>
                                            We offer various type of truck from AUV, 4-10W Dry/Reefer Van, and Tractor Head with nationwide delivery coverage.
                                        </Typography>
                                    </Box>
                                    <Box component={'div'} className='s-desc-list-item-img'>
                                        <img src={ShipperImage4} alt='' />
                                    </Box>
                                </Box>
                            </Fade>
                            <Fade>
                                <Box component={'div'} className='s-desc-list-item'>
                                    <Box component={'div'} className='s-desc-list-item-img'>
                                        <img src={ShipperImage5} alt='' />
                                    </Box>
                                    <Box component={'div'} className='s-desc-cont'>
                                        <Heading title={'Delivery Data Analytics'} dot />
                                        <Typography variant='subtitle1'>
                                            Our Logistics Services Big Data helps clients to have an insight and make decisions based on industry trends and distribution patterns.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Fade>
                            <Fade>
                                <Box component={'div'} className='s-desc-list-item'>
                                    <Box component={'div'} className='s-desc-cont'>
                                        <Heading title={'Real-time Tracking'} dot />
                                        <Typography variant='subtitle1'>
                                            We provide full access of our 24/7 Real-time GPS Fleet Management Portal so you can track your delivery and secure every trip.
                                        </Typography>
                                    </Box>
                                    <Box component={'div'} className='s-desc-list-item-img'>
                                        <img src={ShipperImage6} alt='' />
                                    </Box>
                                </Box>
                            </Fade>
                        </>
                    ) : (
                        <>
                            <Fade>
                                <Box component={'div'} className='s-desc-list-item'>
                                    <Box component={'div'} className='s-desc-cont'>
                                        <Heading title={'Get Additional Trips'} dot />
                                        <Typography variant='subtitle1'>
                                            Not enough delivery trips? We will help you to get more! You can access all trip requests anytime, anywhere! Just download our app and get notified daily on our deliveries!
                                        </Typography>
                                    </Box>
                                    <Box component={'div'} className='s-desc-list-item-img'>
                                        <img src={CarrierImage1} alt='' />
                                    </Box>
                                </Box>
                            </Fade>
                            <Fade>
                                <Box component={'div'} className='s-desc-list-item'>
                                    <Box component={'div'} className='s-desc-list-item-img'>
                                        <img src={CarrierImage2} alt='' />
                                    </Box>
                                    <Box component={'div'} className='s-desc-cont'>
                                        <Heading title={'Schedule Backloads'} dot />
                                        <Typography variant='subtitle1'>
                                            Backloads will not be a problem anymore! You can search and schedule available trips from your current location in our mobile application.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Fade>
                            <Fade>
                                <Box component={'div'} className='s-desc-list-item'>
                                    <Box component={'div'} className='s-desc-cont'>
                                        <Heading title={'Enjoy Fast Payment'} dot />
                                        <Typography variant='subtitle1'>
                                            Tired of long-term payment and delays? Experience faster payment terms and/or get paid immediately after your delivery trip!
                                        </Typography>
                                    </Box>
                                    <Box component={'div'} className='s-desc-list-item-img'>
                                        <img src={CarrierImage3} alt='' />
                                    </Box>
                                </Box>
                            </Fade>
                        </>
                    )
                }
            </Box>

            <Fade>
                <div>
                    <Heading subTitle={t('Contact Us')} title={t('Here to Help Your Business Needs')} dot className='s-contact-us'/>
                    <Typography variant='subtitle1' className='s-contact-us-subtitle'>
                        {t('Stop worrying about any shipping problems. Focus on your business. Let us provide the support you deserve.')}
                    </Typography>
                </div>
            </Fade>
        </Box>
    )
}

export default SignUp;