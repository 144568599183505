export const SET_COUNTRY = (str) => {
    return({
        type: "SET_COUNTRY",
        payload: str
    })
}

export const SHOW_PAGE_LOADER = (str) => {
    return({
        type: "SHOW_PAGE_LOADER",
        payload: str
    })
}

export const SET_DISTANCE_WEIGHT = (obj) => {
    return({
        type: "SET_DISTANCE_WEIGHT",
        payload: obj
    })
}