import React,{ useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import './style.scss';

import { Box, List, ListItem, ListItemIcon, ListItemText, IconButton, Typography } from '@mui/material';

// icons
import { Map, Mail, MailOutline, Phone } from '@mui/icons-material';

// logo
import Logo from '../../assets/img/inteluck-logo-2020.png';

// socials
import SocialList from '../../assets/constants/social-list';

// menu
import MenuList from '../../assets/constants/constant.json';

// country list
import CountryList from '../../assets/constants/country-list';

import history from '../../history';

import {useTranslation} from 'react-i18next';

const Footer = () => {
    const {t} = useTranslation();

    const countryCode = useSelector(state => state.country);
    const [countryDetails, setCountryDetails] = useState({address: '', contact: ''});

    const serviceMenu = MenuList.menu.find(n => n.menu === 'Services');

    const currentYear = new Date().getFullYear();

    useEffect(() => {
        const details = CountryList.find(n => n.code === countryCode);
        setCountryDetails({
            address: details.address,
            contact: details.mobile
        });
    },[]);

    const handleRedirectionPage = (e, url) => {
        e.preventDefault();
        history.push({pathname: `/${countryCode}${url}`});
    }

    return (
        <>
            <Box component={'div'} className='footer'>
                <Box component={'div'} className='f-grid'>
                    <Box component={'div'} className='f-address-cont'>
                        <img src={Logo} alt='' className='f-logo'/>
                        <Box component={'div'}>
                            <List className='f-left-contact'>
                                <ListItem>
                                    <ListItemIcon>
                                        <Map />
                                    </ListItemIcon>
                                    <ListItemText className='footer-address'>
                                        {countryDetails.address}
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Mail />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <a href={`/${countryCode}/`}>www.inteluck.com</a>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Phone />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <a href={`tel:${countryDetails.contact}`}>{countryDetails.contact}</a>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <MailOutline />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <a href='mailto:info@inteluck.com / support@inteluck.com'>info@inteluck.com / support@inteluck.com</a>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Box>
                        <Box component={'div'} className='f-left-socials'>
                            {
                                SocialList.map((item, key) => (
                                    <IconButton key={key} size='large'>
                                        {item.icon}
                                        <a href={item.link} target='_blank' rel='noreferrer'>{item.name[0]}</a>
                                    </IconButton>
                                ))
                            }
                        </Box>
                    </Box>
                    <Box component={'div'} className='f-links'>
                        <Typography variant='h5'>{t('Company')}</Typography>
                        <List>
                            {
                                MenuList.menu.map((item, key) => (
                                    item.menu !== 'Services' && (
                                        <ListItem key={key}>
                                            <a href={item.url} onClick={(e) => handleRedirectionPage(e, item.url)}>{t(item.menu)}</a>
                                        </ListItem>
                                    )
                                ))
                            }
                        </List>
                    </Box>
                    <Box component={'div'} className='f-links'>
                        <Typography variant='h5'>{t('Services')}</Typography>
                        <List>
                            {
                                serviceMenu['sub-menu'].map((item, key) => (
                                    <ListItem key={key}>
                                        <a href={item.url} onClick={(e) => handleRedirectionPage(e, item.url)}>{t(item.menu)}</a>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Box>
                    <Box component={'div'} className='f-links'>
                        <Typography variant='h5'>{t('Information')}</Typography>
                        <List>
                            <ListItem>
                                <a href='/information/data-privacy-policy' onClick={(e) => handleRedirectionPage(e, '/information/data-privacy-policy')}>{t('Data Privacy Policy')}</a>
                            </ListItem>
                            <ListItem>
                                <a href='/information/terms-%26-conditions' onClick={(e) => handleRedirectionPage(e, '/information/terms-%26-conditions')}>{t('Terms & Conditions')}</a>
                            </ListItem>
                        </List>
                    </Box>
                    <Box component={'div'} className='f-links'>
                        <Typography variant='h5'>{t('Useful links')}</Typography>
                        <List>
                            <ListItem>
                                <a href='/get-a-quote' onClick={(e) => handleRedirectionPage(e, '/get-a-quote')}>{t('Get a quote')}</a>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Box>
            <Box component={'div'} className='f-copyright'>
                {`Inteluck © 2014 – ${currentYear}`}
            </Box>
        </>
    )
}

export default Footer;