import React from 'react';

import { useSelector } from 'react-redux';

import './style.scss';

import { Box, Typography, Button } from '@mui/material';

import ThankYouImage from '../../assets/img/thank-you-image.png';

import history from '../../history';

const ThankYou = () => {
    const countryCode = useSelector(state => state.country);

    const handleGoToHome = () => {
        history.push({pathname: `/${countryCode}`});
    }

    return (
        <Box component={'div'} className='thank-you'>
            <Typography variant='h2' className='ty-header'>
                Thank you for the registration, Our team will contact you soon!
            </Typography>
            <Typography variant='subtitle1' className='ty-subtitle-1'>
                Welcome to Inteluck Sentinel Transportation Management Platform V2.0
            </Typography>
            <img src={ThankYouImage} alt='' />
            <Typography variant='subtitle1' className='ty-subtitle-2'>
                We're on the process of verifying your account. We'll update you soonest once it's ready.
            </Typography>
            <Button variant='contained' className='button orange' onClick={handleGoToHome} disableElevation>
                Go back to Homepage
            </Button>
        </Box>
    )
}

export default ThankYou;