import React from 'react';

import './style.scss';

import { Box, Typography } from '@mui/material';

import Fade from 'react-reveal/Fade';

import Heading from '../../../components/Heading';

import {useTranslation} from 'react-i18next';

// images
import InboundOutbound from '../../../assets/img/logistic-optimization-inbound-outbound.jpg';
import IntransitDelivery from '../../../assets/img/logistic-optimization-intransit-delivery.jpg';
import EfficiencyImprovement from '../../../assets/img/logistic-optimization-efficiency-improvement.jpg';

const LogisticsOptimization = () => {
    const {t} = useTranslation();

    return (
        <Box component={'div'} className='logistics-optimization'>
            <Fade>
                <div>
                    <Heading
                        subTitle={t('Our Services')}
                        title={t('Logistics Optimisation')}
                        dot
                    />

                    <Typography variant='h6' className='lo-subtitle'>
                        {t('Flexible solution that provides Inbound & Outbound Process Analyzation, In-transit Delivery Trip Analyzation that leads to Efficiency & Cost Saving.')}
                    </Typography>
                </div>
            </Fade>
            
            <Box component={'div'} className='lo-logistics-optimization-desc-cont'>
                <Fade>
                    <Box component={'div'} className='lo-desc'>
                        <Box component={'div'}>
                            <Heading title={t('Inbound & Outbound Process Analyzation')} dot/>
                            <Typography variant='h6'>
                                {t('We proud ourselves in providing solutions for first and mid-mile logistics companies.We are expert with the logistics process, may it be from inbound or outbound. This is with the help and utilization of the data and our well-experienced team that benefits our clients and partners.')}
                            </Typography>
                        </Box>
                        <img src={InboundOutbound} alt='' />
                    </Box>
                </Fade>
                <Fade>
                    <Box component={'div'} className='lo-desc'>
                        <Box component={'div'}>
                            <Heading title={t('In-Transit Delivery Trip Analyzation')} dot/>
                            <Typography variant='h6'>
                                {t('All the trucks we used are equipped with our advanced Fleet Management Solution. Real-time tracking, driver behavior and location history reports are readily available from our portal. Our clients have the capability to track their delivery and feel secure with every transaction from us.')}
                            </Typography>
                        </Box>
                        <img src={IntransitDelivery} alt='' />
                    </Box>
                </Fade>
                <Fade>
                    <Box component={'div'} className='lo-desc'>
                        <Box component={'div'}>
                            <Heading title={t('Efficiency Improvement & Cost Saving')} dot/>
                            <Typography variant='h6'>
                                {t('Our client and partners can also have an access with our Transportation Management Platform (TMP). With big data on hand, we will help you analyze the available information for the betterment of your logistics process and business. We are your partner in achieving your goals!')}
                            </Typography>
                        </Box>
                        <img src={EfficiencyImprovement} alt='' />
                    </Box>
                </Fade>
            </Box>
        </Box>
    )
}

export default LogisticsOptimization;
