import React, {useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';

import './style.scss';

import { Box, Button, Grid, TextField, FormControlLabel, Checkbox, Collapse } from '@mui/material';

import Heading from '../../components/Heading';

import history from '../../history';

import Cookies from 'universal-cookie';

import Constant from '../../assets/constants/constant.json';

import * as Common from '../../assets/js/common';

// api
import inteluck from '../../apis/inteluck';

import {useTranslation} from 'react-i18next';

const cookies = new Cookies();
const Login = () => {
    const {t} = useTranslation();

    const {type} = useParams();

    const ErrorList = Constant.error;
    const cookiesLogin = cookies.get('web-login-credentials');
    const [loginType, setLoginType] = useState(type);
    const [openToast, setOpenToast] = useState({show: false, message: '', severity: ''});
    const [loginForm, setLoginForm] = useState({email: cookiesLogin ? cookiesLogin.email : '', password: cookiesLogin ? cookiesLogin.password : '', remember: cookiesLogin ? true : false});

    useEffect(() => {
        history.listen((e) => {
            const pathName = e.pathname.split('/');
            setLoginType(pathName[pathName.length - 1]);
        })
    },[]);

    useEffect(() => {
        if(openToast.show && openToast.severity !== 'info'){
            setTimeout(() => {
                setOpenToast({...openToast, 'show': false});
            }, 3000);
        }
    },[openToast])

    const handleLoginOnClick = () => {
        const app = loginType === 'carrier' ? 'trucker' : 'shipper';
        
        if(loginForm.email === '' || loginForm.password === ''){
            if(loginForm.email === ''){
                setOpenToast({show: true, message: 'Email address or Username is empty', severity: 'error'});
            } else if(loginForm.password === ''){
                setOpenToast({show: true, message: 'Password is empty', severity: 'error'});
            }
            
            return;
        }

        if(loginForm.remember){
            cookies.set('web-login-credentials',{email: loginForm.email, password: loginForm.password});
        }else{
            cookies.remove('web-login-credentials');
        }

        setOpenToast({show: true, message: 'Please wait...', severity: 'info'});

        inteluck.post('Accounts/Login',{app, username: loginForm.email, password: loginForm.password})
        .then(res => {
            if(res.status === 200){
                const data = res.data;
                const domain = Common.getDomain();

                setOpenToast({show: true, message: 'Login Success', severity: 'success'});
                
                if(process.env.REACT_APP_NODE_ENV === 'development'){
                    document.cookie = `user-token=${data.token}; path=/; domain=${domain}; login-origin=website`;
                }
                
                document.cookie = `user-token=${data.token}; path=/; domain=${domain}; login-origin=website`;

                setTimeout(() => {
                    switch(loginType){
                        case 'shipper':
                            window.location.replace(process.env.REACT_APP_SHIPPER_ENDPOINT); 
                            break;
                        case 'carrier':
                            window.location.replace(process.env.REACT_APP_TRUCKER_ENDPOINT);
                            break;
                        default:
                            break;
                    }
                }, 2000);
            }
        })
        .catch(err => {
            if(err.response){
                const xhr = err.response;
                const message = xhr.status !== 500 ? xhr.data.message : ErrorList[xhr.status];
                setOpenToast({show: true, message: message, severity: 'error'});
            }
        })
    }

    const handleTypeLoginForm = (e) => {
        const {name, value} = e.target;
        setLoginForm({...loginForm, [name]: value});
    }

    const handleLoginOnEnterButton = (e) => {
        const key = e.keyCode;
        if(key === 13){
            handleLoginOnClick();
        }
    }

    return (
        <Box component={'div'} className='login'>
            <Box component={'div'} className={`l-banner ${loginType}`}>
                <Box component={'div'} className='l-login-form'>
                    <Heading title={t(`${loginType[0].toUpperCase()}${loginType.substring(1)} Login`)} variant='h4' />
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField 
                                autoComplete='off' 
                                placeholder={t('Email Address or Username')}
                                name='email'
                                value={loginForm.email}
                                onChange={handleTypeLoginForm}
                                onKeyUp={handleLoginOnEnterButton}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                type='password' 
                                autoComplete='off' 
                                placeholder={t('Password')}
                                name='password'
                                value={loginForm.password}
                                onChange={handleTypeLoginForm}
                                onKeyUp={handleLoginOnEnterButton}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox className={loginType} checked={loginForm.remember} onChange={() => setLoginForm({...loginForm, 'remember': !loginForm.remember})}/>} label={t("Remember Me")}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant='container' className={`button ${loginType === 'shipper' ? 'green' : 'orange'}`} onClick={handleLoginOnClick}>
                                {t('Submit')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={openToast.show}>
                                <Box component={'div'} className={`toast ${openToast.severity}`}>
                                    {openToast.message}
                                </Box>
                            </Collapse>
                        </Grid>
                    </Grid>
                </Box>
                <Box component={'div'} className='l-banner-overlay' />
            </Box>
        </Box>
    )
}

export default Login;