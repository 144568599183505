import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import './style.scss';

import { Box, Typography, List, ListItem, ListItemIcon, Avatar, ListItemText, Chip, Grid, Button, TextField, InputAdornment, Collapse } from '@mui/material';

import { KeyboardArrowLeft, KeyboardArrowRight, LocationOnOutlined, EmailOutlined, LocalPhone, PriorityHigh } from '@mui/icons-material';

import Heading from '../../components/Heading';

// for slider
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// social links
import SocialList from '../../assets/constants/social-list';

// import country address
import CountriesOffice from '../../assets/constants/country-list';

import * as Common from '../../assets/js/common';

import inteluck from '../../apis/inteluck';

const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

const sliderRef = React.createRef();
const GetAQuote = () => {
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();

    const countryCode = useSelector(state => state.country);

    const [disableFields, setDisableFields] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [openToast, setOpenToast] = useState({show: false, message: '', severity: ''});
    const [sliderDefault, setSliderDefault] = useState();

    useEffect(() => {
        const countryIndex = CountriesOffice.findIndex(n => n.code === countryCode);
        setSliderDefault(countryIndex);
    },[])
    
    const handleSubmitQuote = data => {
        // check if email is in the correct format
        if(!Common.ValidateEmail(data.email)){
            setInvalidEmail(true);
            return
        }

        setDisableFields(true);
        setInvalidEmail(false);
        setOpenToast({show: true, message: 'Please wait...', severity: 'info'});
        inteluck.post('tools/Feedback/Quote',data)
        .then(res => {
            if(res.status === 200){
                Object.keys(data).forEach(item => {
                    setValue(item, '');
                })

                setDisableFields(false);
                setOpenToast({show: true, message: 'Thank you for your submission', severity: 'success'});
                setTimeout(() => {
                    setOpenToast({...openToast, show: false});
                }, 2000);
            }
        })
        .catch(err => {
            setOpenToast({show: true, message: 'Something went wrong', severity: 'error'});
        })
    };

    return (
        <Box component={'div'} className='get-a-quote'>
            <Box component={'div'} className='g-banner'>
                <Box component={'div'} className='g-quote-cont'>
                    <Box component={'div'} className='quote-cont'>
                        <Heading subTitle={'WE ARE INTELUCK'} title={'Get in Touch with Us and We\'ll Help Your Business'} dot/>
                        <Typography variant='subtitle1' className='gq-subtitle'>
                            Looking for flexible logistics services to handle your requirements? Please contact us now and we will be glad to help.
                        </Typography>
                        <Box component={'div'} className='gq-inquiry-right'>
                            <Box component={'div'} className='gq-slider'>
                                <Slider ref={sliderRef} {...sliderSettings} className='gq-slider-cont' initialSlide={sliderDefault}>
                                    {
                                        CountriesOffice.map((item, key) => (
                                            <div className='gq-slider-item' key={key + 1}>
                                                <Heading title={item.country} variant='h4' />
                                                <List className='gq-slider-item-list'>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <Avatar sizes='small'>
                                                                <LocationOnOutlined fontSize='small'/>
                                                            </Avatar>
                                                        </ListItemIcon>
                                                        <ListItemText>{item.address}</ListItemText>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <Avatar sizes='small'>
                                                                <EmailOutlined fontSize='small'/>
                                                            </Avatar>
                                                        </ListItemIcon>
                                                        <ListItemText>support@inteluck.com</ListItemText>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <Avatar sizes='small'>
                                                                <LocalPhone fontSize='small'/>
                                                            </Avatar>
                                                        </ListItemIcon>
                                                        <ListItemText>{item.mobile}</ListItemText>
                                                    </ListItem>
                                                </List>
                                            </div>
                                        ))
                                    }
                                </Slider>
                                <Box component={'div'} className='gq-slider-nav-cont'>
                                    <KeyboardArrowLeft className='gq-nav' onClick={() => sliderRef.current.slickPrev()}/>
                                    <span>|</span>
                                    <KeyboardArrowRight className='gq-nav' onClick={() => sliderRef.current.slickNext()}/>
                                </Box>
                            </Box>
                            <Box component={'div'} className='gq-socials'>
                                {
                                    SocialList.map((item, key) => (
                                        <Box key={key} component={'div'} className='gq-social-item'>
                                            <Chip icon={item.icon} label={item.name} />
                                            <a href={item.link} target='_blank' rel='noreferrer'>{item.name}</a>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Box>
                    <Box component={'div'} className='quote-cont'>
                        <Heading title={'Get A Quote'} />
                        <form onSubmit={handleSubmit(handleSubmitQuote)}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField 
                                        autoComplete='off' 
                                        placeholder='Company' 
                                        {...register('company', {required: true})}
                                        InputProps={
                                            {
                                                endAdornment: errors.company && <InputAdornment><PriorityHigh style={{color: 'red'}}/></InputAdornment>
                                            }
                                        }
                                        disabled={disableFields}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField 
                                        autoComplete='off' 
                                        placeholder='First Name' 
                                        {...register('firstname', {required: true})}
                                        InputProps={
                                            {
                                                endAdornment: errors.firstname && <InputAdornment><PriorityHigh style={{color: 'red'}}/></InputAdornment>
                                            }
                                        }
                                        disabled={disableFields}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField 
                                        autoComplete='off' 
                                        placeholder='Last Name' 
                                        {...register('lastname', {required: true})}
                                        InputProps={
                                            {
                                                endAdornment: errors.lastname && <InputAdornment><PriorityHigh style={{color: 'red'}}/></InputAdornment>
                                            }
                                        }
                                        disabled={disableFields}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField 
                                        autoComplete='off' 
                                        placeholder='Mobile/Phone Number' 
                                        inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}} 
                                        {...register('contact', {required: true})}
                                        InputProps={
                                            {
                                                endAdornment: errors.contact && <InputAdornment><PriorityHigh style={{color: 'red'}}/></InputAdornment>
                                            }
                                        }
                                        disabled={disableFields}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField 
                                        autoComplete='off' 
                                        placeholder='Email' 
                                        {...register('email', {required: true})}
                                        InputProps={
                                            {
                                                endAdornment: (errors.email || invalidEmail) && <InputAdornment><PriorityHigh style={{color: 'red'}}/></InputAdornment>
                                            }
                                        }
                                        disabled={disableFields}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        autoComplete='off' 
                                        placeholder='Type of Goods' 
                                        {...register('goods', {required: true})}
                                        InputProps={
                                            {
                                                endAdornment: errors.goods && <InputAdornment><PriorityHigh style={{color: 'red'}}/></InputAdornment>
                                            }
                                        }
                                        disabled={disableFields}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        autoComplete='off' 
                                        placeholder='Requirement details'
                                        multiline
                                        rows={7}
                                        {...register('details', {required: true})}
                                        InputProps={
                                            {
                                                endAdornment: errors.details && <InputAdornment><PriorityHigh style={{color: 'red'}}/></InputAdornment>
                                            }
                                        }
                                        disabled={disableFields} 
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant='contained' className='button orange' disableElevation type='submit' disabled={disableFields}>
                                        Submit
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Collapse in={openToast.show}>
                                        <Box component={'div'} className={`toast ${openToast.severity}`}>
                                            {openToast.message}
                                        </Box>
                                    </Collapse>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
                <Box component={'div'} className='g-banner-overlay' />
            </Box>
        </Box>
    )
}

export default GetAQuote;