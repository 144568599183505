import Cookies from 'universal-cookie';

const cookies = new Cookies();

const countryReducer = (state = cookies.get('web-country') || 'SG', action) => {
    switch (action.type) {
        case 'SET_COUNTRY':
            return action.payload;
        default:
            return state;
    }
}

export default countryReducer;